import { NotificationTypeEnum } from 'modules/profile/notification-settings/enums/notification-settings-enum'
import CommentIcon from 'shared/icons/comment-icon'
import CommunityIcon from 'shared/icons/community-icon'
import InfoIcon from 'shared/icons/info-icon'
import LikeIcon from 'shared/icons/like-icon'
import WarningIcon from 'shared/icons/warning-icon'
import { twMerge } from 'tailwind-merge'
import MentionIcon from '../../../shared/icons/mention-icon'

export const ruleIconSizeClassName = 'h-[20px] min-w-[20px] w-[20px] fill-gray-400 text-gray-400 group-hover:text-darkblue group-hover:fill-darkblue transition'

export const getNotificationIcon = (notificationType: NotificationTypeEnum) => {
  switch(notificationType) {
    case NotificationTypeEnum.CommentOnCommunityPost:
    case NotificationTypeEnum.CommentReplyOnComment:
    case NotificationTypeEnum.CommentOnCourseCreatedByMe:
      return <CommentIcon className={ruleIconSizeClassName} />
    case NotificationTypeEnum.MentionInCommentOnCommunityPost:
      return <MentionIcon className={ruleIconSizeClassName} />
    case NotificationTypeEnum.CommunityNewPendingPost:
    case NotificationTypeEnum.CommunityPostIsPublished:
      return <CommunityIcon className={ruleIconSizeClassName} />
    case NotificationTypeEnum.CommunityPostIsLiked:
    case NotificationTypeEnum.CommentIsLiked:
      return <LikeIcon className={ruleIconSizeClassName} />
    case NotificationTypeEnum.WithdrawalMethodOfAffiliateIsNotSet:
    case NotificationTypeEnum.ProfileInformationOfAffiliateIsNotFilled:
    case NotificationTypeEnum.DomainAuthenticationsNotValid:
    case NotificationTypeEnum.AffiliatePayoutFailed:
      return <WarningIcon className={twMerge(ruleIconSizeClassName, 'fill-yellow')} />
    default:
      return <InfoIcon className={twMerge(ruleIconSizeClassName, 'fill-yellow')} />
  }
}
