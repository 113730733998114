import React from 'react'

function SalesIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 18"
      fill="#ACB8D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.596903 0.917895H22.0412V17.965H0.596903V0.917895Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4904 11.5919C14.4889 12.8741 13.51 13.7907 11.9891 14.0086V15.2422H10.4494V13.9815C9.56536 13.8131 8.7314 13.3834 8.1574 12.7757L9.27598 11.7165C9.72924 12.1961 10.5288 12.5187 11.2642 12.5187C12.0426 12.5187 12.9512 12.2747 12.9512 11.5884L12.9512 11.5824C12.9574 10.7646 12.4136 10.5177 11.0744 10.1125C10.4491 9.92271 9.80186 9.72672 9.27112 9.36168C8.57333 8.88354 8.21849 8.20563 8.21849 7.35083C8.21849 6.13028 9.08072 5.23477 10.4494 4.93337V3.64022H11.9892V4.84862C12.7328 4.92422 13.4859 5.1678 14.0361 5.52488L13.2014 6.81895C12.7804 6.54715 12.1025 6.36375 11.5132 6.36375C10.7041 6.36375 9.75897 6.62279 9.75897 7.35084C9.75897 8.00445 10.155 8.22269 11.5203 8.63705C12.1757 8.83489 12.8534 9.04079 13.4118 9.43549C14.1347 9.94814 14.4972 10.6741 14.4904 11.5919L14.4904 11.5919Z"
        fill="white"
      />
    </svg>
  )
}

export default SalesIcon
