import Cookie from 'js-cookie'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'

export const setSelectedFunnelStepCookie = (funnelId: FunnelId, funnelStepId: FunnelStepId) => {
  Cookie.set(`funnel_${funnelId}_selected_step`, funnelStepId.toString())
}

export const getSelectedFunnelStepCookie = (funnelId: FunnelId) =>
  Cookie.get(`funnel_${funnelId}_selected_step`)

export const getSelectedFunnelStepOldCookie = (funnelId: FunnelId) =>
  Cookie.get(`funnel-${funnelId}-selected-step`)

export const setIsEmailStatusNotificationClosedCookie = () =>
  Cookie.set(`isEmailStatusNotificationClosed`, '1')

export const getIsEmailStatusNotificationClosedCookie = () =>
  Cookie.get('isEmailStatusNotificationClosed')
