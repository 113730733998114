export enum MailingStatusEnum {
  pending = 'pending',
  common = 'common',
  blocked = 'blocked',
  approved = 'approved',
  new = 'new',
  new_pending = 'new_pending',
}

export const mailingStatusLocoKeys: Record<MailingStatusEnum, string> = {
  [MailingStatusEnum.pending]: 'dashboard.user.status.pending',
  [MailingStatusEnum.common]: 'dashboard.user.status.common',
  [MailingStatusEnum.blocked]: 'dashboard.user.status.blocked',
  [MailingStatusEnum.approved]: 'dashboard.user.status.approved',
  [MailingStatusEnum.new]: 'dashboard.user.status.new',
  [MailingStatusEnum.new_pending]: 'dashboard.user.status.new_pending',
}
