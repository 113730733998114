import React from 'react'

function DotsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="#142D63"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2.50928" cy="2.03174" r="1.90771" />
      <circle cx="9.06396" cy="2.03174" r="1.90771" />
      <circle cx="15.6187" cy="2.03174" r="1.90771" />
    </svg>
  )
}

export default DotsIcon
