import React from 'react'

function LogoutIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75435 1.875L9 1.875C9.62132 1.875 10.125 2.37868 10.125 3C10.125 3.62132 9.62132 4.125 9 4.125H7.8C6.94136 4.125 6.36504 4.12588 5.92122 4.16214C5.49053 4.19733 5.28461 4.26015 5.14877 4.32936C4.79597 4.50913 4.50913 4.79596 4.32936 5.14877C4.26015 5.28461 4.19733 5.49053 4.16214 5.92122C4.12588 6.36504 4.125 6.94136 4.125 7.8V16.2C4.125 17.0586 4.12588 17.635 4.16214 18.0788C4.19733 18.5095 4.26015 18.7154 4.32936 18.8512C4.50913 19.204 4.79596 19.4909 5.14877 19.6706C5.28461 19.7399 5.49053 19.8027 5.92122 19.8379C6.36504 19.8741 6.94136 19.875 7.8 19.875H9C9.62132 19.875 10.125 20.3787 10.125 21C10.125 21.6213 9.62132 22.125 9 22.125H7.75432C6.9531 22.125 6.28434 22.125 5.738 22.0804C5.16817 22.0338 4.63318 21.9332 4.12729 21.6754C3.35112 21.2799 2.72008 20.6489 2.3246 19.8727C2.06683 19.3668 1.96617 18.8318 1.91961 18.262C1.87497 17.7157 1.87498 17.0469 1.875 16.2457V7.75435C1.87498 6.95312 1.87497 6.28435 1.91961 5.738C1.96617 5.16817 2.06683 4.63318 2.3246 4.12729C2.72008 3.35112 3.35112 2.72008 4.12729 2.3246C4.63318 2.06683 5.16817 1.96617 5.738 1.91961C6.28435 1.87497 6.95312 1.87498 7.75435 1.875ZM15.2045 6.20451C15.6438 5.76517 16.3562 5.76517 16.7955 6.20451L21.7955 11.2045C22.2348 11.6438 22.2348 12.3562 21.7955 12.7955L16.7955 17.7955C16.3562 18.2348 15.6438 18.2348 15.2045 17.7955C14.7652 17.3562 14.7652 16.6438 15.2045 16.2045L18.284 13.125H9C8.37868 13.125 7.875 12.6213 7.875 12C7.875 11.3787 8.37868 10.875 9 10.875H18.284L15.2045 7.7955C14.7652 7.35616 14.7652 6.64385 15.2045 6.20451Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LogoutIcon
