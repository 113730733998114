export enum NotificationSettingsEnum {
  communityCommented = 'communityCommented',
  courseCommented = 'courseCommented',
  disableCompanyCustomerPurchase = 'disableCompanyCustomerPurchase',
  disableIndividualCustomerPurchase = 'disableIndividualCustomerPurchase',
  newAffiliateSell = 'newAffiliateSell',
  paymentNewSell = 'paymentNewSell',
}

export enum NotificationSettingsTitleEnum {
  communityCommented = 'dashboard.settings.notifications.community_comment',
  courseCommented = 'dashboard.settings.notifications.course_comment',
  disableCompanyCustomerPurchase = 'dashboard.settings.notifications.customer_made_purchase_company',
  disableIndividualCustomerPurchase = 'dashboard.settings.notifications.customer_made_purchase_individual',
  newAffiliateSell = 'dashboard.settings.notifications.affiliate_sale',
  paymentNewSell = 'dashboard.settings.notifications.sale_offer',
}

export enum NotificationSettingsGroupEnum {
  sales = 'sales',
  comments = 'comments',
  subscription = 'subscription',
}

export const notificationSettingsSectionTitles: Record<NotificationSettingsGroupEnum, string> = {
  [NotificationSettingsGroupEnum.sales]: 'dashboard.settings.notifications.section.sales',
  [NotificationSettingsGroupEnum.comments]: 'dashboard.settings.notifications.section.comments',
  [NotificationSettingsGroupEnum.subscription]:
    'dashboard.settings.notifications.section.subscription',
}

export enum NotificationTypeEnum {
  CommentReplyOnComment = 1,
  CommentOnCommunityPost = 2,
  CommentOnCourseCreatedByMe = 3,
  CommunityNewPendingPost = 4,
  CommunityPostIsPublished = 5,
  CommunityPostIsLiked = 6,
  CommentIsLiked = 7,
  WithdrawalMethodOfAffiliateIsNotSet = 8,
  ProfileInformationOfAffiliateIsNotFilled = 9,
  DomainAuthenticationsNotValid = 10,
  AffiliatePayoutFailed = 11,
  MentionInCommentOnCommunityPost = 12,
}
