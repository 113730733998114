import { ParsedUrlQuery } from 'querystring'
import { Dispatch, MutableRefObject, SetStateAction } from 'react'

const arrayFilterKeys = ['offerPricePlanIds', 'contactIds', 'roles'] as const

export const setFilterQuery = <T>({
  queryRef,
  filterKeys,
  setFilter,
}: {
  queryRef: MutableRefObject<ParsedUrlQuery>
  filterKeys: MutableRefObject<(keyof T)[]>
  setFilter: Dispatch<SetStateAction<T>>
}) => {
  const newFilter = {} as T
  const query = { ...queryRef.current } as T & Record<string, string | string[] | undefined>
  const arrayFilterKeysArray: Array<number | string> = []
  filterKeys.current.forEach(el => {
    const arrayFilterKey = arrayFilterKeys.find(key => key === el) as keyof T
    //for predefined selector
    if (query[el] === '') newFilter[el] = undefined as unknown as T[keyof T]
    if (query[el]) {
      if (arrayFilterKey) {
        if (typeof query[el] === 'string') {
          if (!isNaN(Number(query[el]))) {
            arrayFilterKeysArray.push(Number(query[el]))
          } else {
            arrayFilterKeysArray.push(query[el] as string)
          }
        } else if (Array.isArray(query[el])) {
          ;(query[el] as []).forEach(item => {
            if (!isNaN(Number(item))) {
              arrayFilterKeysArray.push(Number(item))
            } else {
              arrayFilterKeysArray.push(item as string)
            }
          })
        }
      } else {
        if (!isNaN(Number(query[el]))) {
          newFilter[el] = Number(query[el]) as unknown as T[keyof T]
        } else {
          newFilter[el] = query[el]
        }
      }
    }
    //for transaction > offerPricePlan filter
    if (arrayFilterKeysArray.length !== 0 && arrayFilterKey) {
      newFilter[arrayFilterKey] = arrayFilterKeysArray as unknown as T[keyof T]
    }
  })

  setFilter(prev => ({ ...prev, ...newFilter }))
}
