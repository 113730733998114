import { useCallback } from 'react'
import toast, { ToastOptions } from 'react-hot-toast'
import ToastContainer from 'shared/components/toast/components/ToastContainer'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'

export const useCustomToasts = () => {
  const infoToast = useCallback(
    (message: string | JSX.Element, options?: ToastOptions, afterClose?: () => void) =>
      toast.custom(t => <ToastContainer t={{ ...t, message }} afterClose={afterClose} />, {
        ...options,
        duration: 600000,
        icon: (
          <QuestionMarkIcon className="flex-shrink-0 fill-blue w-[16px] sm:w-[20px] h-[16px] sm:h-[20px]" />
        ),
        className: options?.className,
      }),
    [],
  )
  return { infoToast }
}
