import { useRouter } from 'next/router'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import { getDefaultDashboardUrl } from 'shared/utils/get-default-dashboard-url'

const logoUrl = `${process.env.NEXT_PUBLIC_IMG_ASSETS}/logo_small.svg`

const SioButton = () => {
  const router = useRouter()
  const { user, isDisabled } = useUser()

  return user && !isDisabled ? (
    <button
      className={'focus-visible:ring-2 ring-white ring-offset-0 rounded-full'}
      onClick={() => router.push(getDefaultDashboardUrl(user))}
    >
      <LinkWithoutPrefetch
        className={'focus:outline-none tab'}
        tabIndex={-1}
        href={getDefaultDashboardUrl(user)}
      >
        <img className="max-w-[35px] max-h-[35px]" src={logoUrl} alt="Systeme.io" />
      </LinkWithoutPrefetch>
    </button>
  ) : (
    <img className="max-w-[35px] max-h-[35px]" src={logoUrl} alt="Systeme.io" />
  )
}

export default SioButton
