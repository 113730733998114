import { useRollbar } from '@rollbar/react'
import { i18n } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { UserInterface } from 'shared/types/user-interface'
import useSWR, { useSWRConfig } from 'swr'
import { DASHBOARD_USER_DATA_API, PUBLIC_USER_DATA_API } from '../constants/api'
import { publicRoutes } from '../constants/public-routes'
import { isAuthorized } from '../utils/is-authorized'

export const useDashboardUser = () => {
  const router = useRouter()
  const rollbar = useRollbar()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, UserInterface>({
    method: RequestMethodsEnum.get,
    stopErrorPropagation: true,
  })

  const { cache } = useSWRConfig()

  const publicUser = cache.get(PUBLIC_USER_DATA_API)?.data

  const isDashboard = !publicRoutes.some(route => router.pathname.includes(route))

  const { data: dashboardUser, mutate: dashboardMutate } = useSWR<UserInterface>(
    isDashboard && DASHBOARD_USER_DATA_API,
    fetcher,
    {
      fallbackData: publicUser && isAuthorized(publicUser) ? publicUser : undefined,
    },
  )

  useEffect(() => {
    if (dashboardUser && isDashboard) {
      i18n?.changeLanguage(dashboardUser.dashboardLocale)
      rollbar.configure({
        payload: {
          person: {
            id: dashboardUser.id,
            username: `${dashboardUser.firstName} ${dashboardUser.lastName}`,
          },
        },
      })
    }
  }, [dashboardUser, rollbar, router, isDashboard])
  const isDisabled = dashboardUser ? !dashboardUser.impersonator && !dashboardUser.enabled : false

  return { dashboardUser, dashboardMutate, isDisabled }
}
