import { Float } from '@headlessui-float/react'
import { Popover } from '@headlessui/react'
import React from 'react'
import { useHideItem } from 'shared/components/header/hooks/use-hide-item'
import { useIsActive } from 'shared/components/header/hooks/use-is-active'
import {
  MenuItemInterface,
  SingleItemDropdownInterface,
  SingleItemHrefInterface,
} from 'shared/components/header/types/menu-interface'
import { isFlatMenuItem } from 'shared/components/header/utils/is-flat-menu-item'
import { replaceHelpHref } from 'shared/components/header/utils/replace-help-href'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import useUser from 'shared/hooks/use-user'
import ArrowLeftIcon from 'shared/icons/arrow-left-icon'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'

const DesktopMenuItem = ({ item }: MenuItemInterface) =>
  isFlatMenuItem(item) ? <TopMenuItemFlat {...item} /> : <TopMenuDropdown {...item} />

export default DesktopMenuItem

function TopMenuItemFlat(item: SingleItemHrefInterface) {
  const isActive = useIsActive(item)
  const { user, isWsAssistant } = useUser()
  const needToHide = useHideItem()
  if (!user) return null

  if (needToHide(item)) return null

  const href = isWsAssistant ? item.assistantLink || item.href : item.href

  return (item.showForRoles || user.roles).some(role => user.roles.includes(role)) ? (
    <div className="flex justify-center items-center relative transition-all group h-full">
      <LinkWithoutPrefetch
        className={`h-full flex items-center ${
          isActive ? 'text-blue' : ''
        } border-b-4 font-bold transition-all delay-100 tracking-wide hover:text-white hover:border-blue text-sm ${
          isActive ? 'border-blue text-white' : 'border-transparent text-gray-200'
        } outline-none focus-visible:border-blue focus-visible:text-white`}
        href={replaceHelpHref(href, user.dashboardLocale)}
        target={item.target}
      >
        {item.name}
      </LinkWithoutPrefetch>
    </div>
  ) : null
}

function TopMenuDropdown(item: SingleItemDropdownInterface) {
  const isActive = useIsActive(item)
  const needToHide = useHideItem()
  const { show, delayClose, delayOpen } = useFloatHover()
  const filteredItems = item.dropdownItems.filter(item => !needToHide(item))
  if (filteredItems.length === 0) return null
  return (
    <Popover className={'h-full'}>
      <Float
        show={show}
        offset={-5}
        arrow={5}
        placement="bottom"
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={'h-full'}
      >
        <Popover.Button
          type="button"
          className={`h-full main-transition-colors delay-100 border-b-4 tracking-wide group-hover:text-white font-bold text-sm inline-flex items-center ${
            isActive || show ? 'border-blue text-white' : 'border-transparent text-gray-200'
          } focus:outline-none`}
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
        >
          {item.name}
          <ChevronDownIcon className="ml-2" />
        </Popover.Button>

        <Popover.Panel
          className="bg-white rounded-lg shadow-lg z-50"
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
        >
          <Float.Arrow className={`absolute bg-white w-4 h-4 rotate-45`} />
          <TopMenuDropdownPanel dropdownItems={filteredItems} />
        </Popover.Panel>
      </Float>
    </Popover>
  )
}

function TopSubMenu(item: SingleItemDropdownInterface) {
  const isActive = useIsActive(item)
  const { show, delayClose, delayOpen } = useFloatHover()
  const needToHide = useHideItem()
  const filteredItems = item.dropdownItems.filter(item => !needToHide(item))
  if (filteredItems.length === 0) return null
  return (
    <Popover>
      <Float
        show={show}
        offset={15}
        placement="right"
        flip
        arrow={5}
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={'h-full'}
      >
        <Popover.Button
          type="button"
          className={getSubItemClass(isActive || show)}
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
        >
          {item.name}
          <ArrowLeftIcon
            className={`rotate-180 group-hover:fill-blue main-transition-colors ${
              show || isActive ? 'fill-blue' : 'fill-gray-300'
            }`}
          />
        </Popover.Button>

        <Popover.Panel
          className="bg-white rounded-lg shadow-lg z-50"
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
        >
          <Float.Arrow className={`absolute bg-white w-5 h-5 rotate-45`} />
          <TopMenuDropdownPanel dropdownItems={filteredItems} />
        </Popover.Panel>
      </Float>
    </Popover>
  )
}

function TopMenuDropdownPanel({
  dropdownItems,
}: Pick<SingleItemDropdownInterface, 'dropdownItems'>) {
  const { user, isWsAssistant } = useUser()
  if (!user) return null
  return (
    <ul className="flex flex-col whitespace-nowrap text-gray-300 text-sm py-4">
      {dropdownItems.map(item => {
        if (isFlatMenuItem(item)) {
          return (
            (item.showForRoles || user.roles).some(role => user.roles.includes(role)) && (
              <li key={item.href}>
                <TopMenuSubItem
                  {...item}
                  href={isWsAssistant ? item.assistantLink || item.href : item.href}
                />
              </li>
            )
          )
        } else {
          return (
            <li key={item.name}>
              <TopSubMenu {...item} />
            </li>
          )
        }
      })}
    </ul>
  )
}

export function TopMenuSubItem(item: SingleItemHrefInterface) {
  const isActive = useIsActive(item)
  return (
    <LinkWithoutPrefetch className={getSubItemClass(isActive)} href={item.href}>
      {item.name}
    </LinkWithoutPrefetch>
  )
}

const getSubItemClass = (isActive: boolean) =>
  `flex main-transition-colors ${
    isActive ? 'text-blue' : 'text-gray-300'
  } px-7 py-1 hover:text-blue focus:outline-none focus-visible:text-blue w-full items-center justify-between group`
