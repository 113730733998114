import React from 'react'

function WarningIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="#F04438"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76619 3.01532C10.5352 1.67791 12.4648 1.67792 13.2338 3.01533L21.2768 17.0031C22.0434 18.3364 21.081 20 19.5429 20H3.45705C1.91902 20 0.956577 18.3364 1.72324 17.0031L9.76619 3.01532Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7031 8.24512L12.471 14.5307H10.4353L10.2032 8.24512H12.7031ZM11.4531 17.5306C11.1079 17.5306 10.8125 17.4093 10.567 17.1668C10.3215 16.9242 10.2002 16.6289 10.2032 16.2807C10.2002 15.9414 10.3215 15.6512 10.567 15.4101C10.8125 15.1691 11.1079 15.0485 11.4531 15.0485C11.7805 15.0485 12.0699 15.1691 12.3214 15.4101C12.5729 15.6512 12.7001 15.9414 12.7031 16.2807C12.7001 16.6289 12.5729 16.9242 12.3214 17.1668C12.0699 17.4093 11.7805 17.5306 11.4531 17.5306Z"
        fill="white"
      />
    </svg>
  )
}

export default WarningIcon
