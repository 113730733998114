import React from 'react'

function ProductsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="23"
      height="28"
      x="0"
      y="0"
      viewBox="0 0 477.869 477.869"
    >
      <g>
        <path
          d="m192.951 171.027 32.913-54.878L29.598 61.195l-26.24 43.887c-6.642 11.052-3.068 25.396 7.984 32.038a23.373 23.373 0 0 0 5.729 2.471l149.581 41.907a23.339 23.339 0 0 0 26.299-10.471z"
          fill="#94a3b8"
          data-original="#000000"
        ></path>
        <path
          d="m40.35 312.322 70.588 19.277v-84.13c0-18.851 15.282-34.133 34.133-34.133h85.333v-71.569l-22.818 38.042c-9.378 15.59-28.009 23.003-45.534 18.116l-127.914-35.79V304.13a8.532 8.532 0 0 0 6.212 8.192zM238.938 102.086l181.931-50.935L243.511.633a16.913 16.913 0 0 0-9.173 0L57.007 51.151zM437.559 312.322a8.533 8.533 0 0 0 6.178-8.192V162.135l-127.915 35.84a39.808 39.808 0 0 1-10.846 1.493 40.48 40.48 0 0 1-34.688-19.627l-22.818-38.076v71.569h85.333c18.851 0 34.133 15.282 34.133 34.133v84.13z"
          fill="#94a3b8"
          data-original="#000000"
        ></path>
        <path
          d="M284.924 171.027a23.363 23.363 0 0 0 26.3 10.47l149.581-41.907c12.415-3.483 19.656-16.371 16.173-28.786a23.366 23.366 0 0 0-2.459-5.706l-26.223-43.904-196.267 54.955zM349.871 460.802V247.469c0-9.426-7.641-17.067-17.067-17.067H145.071c-9.426 0-17.067 7.641-17.067 17.067v213.333c0 9.426 7.641 17.067 17.067 17.067h187.733c9.426 0 17.067-7.641 17.067-17.067zm-147.567-36.634-25.6 25.6a8.534 8.534 0 0 1-12.066 0l-8.533-8.533a8.534 8.534 0 0 1 12.066-12.066l2.5 2.5 19.567-19.567a8.534 8.534 0 0 1 12.066 12.066zm0-51.2-25.6 25.6a8.534 8.534 0 0 1-12.066 0l-8.533-8.533a8.534 8.534 0 0 1 12.066-12.066l2.5 2.5 19.567-19.567a8.534 8.534 0 0 1 12.066 12.066zm0-51.2-25.6 25.6a8.534 8.534 0 0 1-12.066 0l-8.533-8.533a8.534 8.534 0 0 1 12.066-12.066l2.5 2.5 19.567-19.567a8.534 8.534 0 0 1 12.066 12.066zm0-51.2-25.6 25.6a8.534 8.534 0 0 1-12.066 0l-8.533-8.533a8.534 8.534 0 0 1 12.066-12.066l2.5 2.5 19.567-19.567a8.534 8.534 0 0 1 12.066 12.066zm113.434 173.167h-85.333a8.533 8.533 0 0 1 0-17.066h85.333a8.533 8.533 0 0 1 0 17.066zm0-51.2h-85.333a8.533 8.533 0 0 1 0-17.066h85.333a8.533 8.533 0 0 1 0 17.066zm0-51.2h-85.333a8.533 8.533 0 0 1 0-17.066h85.333a8.533 8.533 0 0 1 0 17.066zm0-51.2h-85.333a8.533 8.533 0 0 1 0-17.066h85.333a8.533 8.533 0 0 1 0 17.066z"
          fill="#94a3b8"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  )
}

export default ProductsIcon
