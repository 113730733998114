import { LocaleEnum } from 'shared/enums/locale-enum'

export const getEmailRestrictLink = (locale?: LocaleEnum) => {
  switch (locale) {
    case LocaleEnum.FRENCH: {
      return 'https://aide.systeme.io/article/226-pourquoi-mes-emails-ne-partent-pas'
    }
    case LocaleEnum.PORTUGUESE: {
      return 'https://help-pt.systeme.io/article/712-por-que-meus-emails-nao-estao-sendo-enviados'
    }
    case LocaleEnum.SPANISH: {
      return 'https://help-es.systeme.io/article/898-mis-correos-electronicos-no-estan-siendo-enviados'
    }
    case LocaleEnum.DEUTSCH: {
      return 'https://help-de.systeme.io/article/1032-warum-werden-meine-e-mails-nicht-gesendet'
    }
    default: {
      return 'https://help.systeme.io/article/227-my-emails-are-not-being-sent'
    }
  }
}
