export default function ThinArrowRightIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3840_14182)">
        <path d="M3.125 10H16.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M11.25 4.375L16.875 10L11.25 15.625"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3840_14182">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
