import React from 'react'

function DashboardIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19"
      height="28"
      viewBox="0 0 19 19"
      fill="#ACB7D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2301 7.70151V0.805664H16.1408C17.2245 0.805664 18.1111 1.69227 18.1111 2.7759V7.7015L10.2301 7.70151ZM10.2301 9.67175H18.1111V16.5676C18.1111 17.6512 17.2245 18.5378 16.1408 18.5378H10.2301V9.67175ZM8.25988 18.5378H2.34915C1.26552 18.5378 0.378906 17.6512 0.378906 16.5676V2.77591C0.378906 1.69228 1.26552 0.805674 2.34915 0.805674H8.25988V18.5378Z"
      />
    </svg>
  )
}

export default DashboardIcon
