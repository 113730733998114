import React from 'react'
import { Toaster } from 'react-hot-toast'
import ToastContainer from 'shared/components/toast/components/ToastContainer'
import CheckCircleIcon from 'shared/icons/check-circle-icon'
import CloseCircleIcon from 'shared/icons/close-circle-icon'

const Toast = () => {
  return (
    <Toaster
      position="top-center"
      containerStyle={{
        top: 80,
        right: 20,
      }}
      containerClassName="[&_a]:primary-link"
      toastOptions={{
        success: {
          icon: (
            <CheckCircleIcon className="flex-shrink-0 fill-green w-[16px] sm:w-[20px] h-[16px] sm:h-[20px]" />
          ),
          duration: 3000,
        },
        error: {
          icon: (
            <CloseCircleIcon className="flex-shrink-0 fill-danger w-[16px] sm:w-[20px] h-[16px] sm:h-[20px]" />
          ),
          duration: 3000,
        },
      }}
    >
      {t => <ToastContainer t={t} />}
    </Toaster>
  )
}

export default Toast
