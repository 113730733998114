import React from 'react'

function ContactsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="18"
      height="28"
      viewBox="0 0 15 16"
      fill="#ACB8D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8003 14.1178C14.8809 14.2272 14.9288 14.3572 14.9386 14.4927C14.9484 14.6283 14.9196 14.7638 14.8556 14.8837C14.7924 15.0028 14.6981 15.1026 14.5828 15.1724C14.4675 15.2423 14.3354 15.2796 14.2006 15.2805H0.915116C0.780298 15.2796 0.648229 15.2423 0.532917 15.1724C0.417596 15.1026 0.323321 15.0028 0.260071 14.8837C0.196071 14.7638 0.167306 14.6283 0.177094 14.4927C0.186893 14.3572 0.234845 14.2272 0.315422 14.1178C1.34697 12.6424 2.8055 11.5185 4.49481 10.8971C3.50454 10.2391 2.75237 9.27969 2.3496 8.16087C1.94683 7.04204 1.91487 5.82324 2.25844 4.68484C2.60202 3.54643 3.30287 2.54891 4.2573 1.8399C5.21172 1.13088 6.36899 0.748043 7.55785 0.748043C8.74671 0.748043 9.90397 1.13088 10.8584 1.8399C11.8128 2.54892 12.5137 3.54643 12.8573 4.68484C13.2008 5.82325 13.1689 7.04204 12.7661 8.16087C12.3633 9.27969 11.6112 10.2391 10.6209 10.8971C12.3102 11.5185 13.7687 12.6424 14.8003 14.1178Z"
      />
    </svg>
  )
}

export default ContactsIcon
