import React, { useEffect, useRef } from 'react'
import toast, { ToasterProps } from 'react-hot-toast'
import {
  getBorderColor,
  getCloseIconColors,
} from 'shared/components/toast/utils/get-colors-by-type'
import useUser from 'shared/hooks/use-user'
import CloseIcon from 'shared/icons/close-icon'

interface ToastContainerProps {
  t: Parameters<Required<ToasterProps>['children']>[0]
  afterClose?: () => void
}

const ToastContainer = ({ t, afterClose }: ToastContainerProps) => {
  const { user } = useUser()
  const closeRef = useRef<HTMLButtonElement>(null)
  const onCloseToast = () => {
    if (closeRef.current) {
      closeRef.current.click()
    }
  }

  useEffect(() => {
    if (!user) {
      onCloseToast()
    }
  }, [user])

  const borderColor = getBorderColor(t.type)

  const closeIconColors = getCloseIconColors(t.type)

  return (
    <div
      className={`${
        t.visible ? 'animate-toast-enter' : 'animate-toast-leave'
      } shadow-lg flex gap-2.5 items-center bg-white border rounded-lg px-2.5 py-2 text-gray-300 max-w-[350px] ${borderColor}`}
    >
      {t.icon}
      <div className={'break-words'}>{t.message as string | JSX.Element}</div>
      <button
        ref={closeRef}
        className={'h-fit outline-none group'}
        onClick={() => {
          toast.dismiss(t.id)
          afterClose && afterClose()
        }}
      >
        <CloseIcon className={`fill-gray-100 main-transition-colors ${closeIconColors}`} />
      </button>
    </div>
  )
}

export default ToastContainer
