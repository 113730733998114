import { AxiosInstance } from 'axios'
import { ErrorResponseInterface } from 'shared/types/error-response-content-interface'

export enum RequestMethodsEnum {
  get = 'get',
  delete = 'delete',
  head = 'head',
  options = 'options',
  post = 'post',
  put = 'put',
  patch = 'patch',
  postForm = 'postForm',
}

export const methodsWithoutBody = [
  RequestMethodsEnum.get,
  RequestMethodsEnum.delete,
  RequestMethodsEnum.head,
  RequestMethodsEnum.options,
]

export type UseApiWrapperWithErrorValidationProps<
  R,
  M extends RequestMethodsEnum,
  BadRequestError = ErrorResponseInterface,
> = {
  method: M
  showFieldsErrorToast?: boolean
  badRequestHandler?: null | ((error: BadRequestError) => void)
  unprocessableContentHandler?: (error: unknown) => void
  unauthorizedResponseHandler?: (error: unknown) => void
  internalErrorHandler?: (error: unknown) => void
  afterFetch?: (data: R) => Promise<R> | R
  stopErrorPropagation?: boolean
}

export type FetcherType<M extends RequestMethodsEnum, R = void> = (
  ...args: Parameters<AxiosInstance[M]>
) => Promise<R>
