const NotificationSkeleton = () => (
  <div className="w-full bg-gray-600 animate-pulse h-[80px] rounded" />
)

const NotificationsListSkeleton = () => (
  <div className="flex px-6 py-4 w-full justify-center items-center flex-col gap-3 bg-white">
    <NotificationSkeleton />
    <NotificationSkeleton />
    <NotificationSkeleton />
  </div>
)

export default NotificationsListSkeleton
