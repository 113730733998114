import NotificationMenu from 'modules/notifications/components/notifications-menu'
import React from 'react'
import DesktopMenuItem from 'shared/components/header/components/desktop-menu/components/desktop-menu-item'
import DesktopMenuSkeleton from 'shared/components/header/components/desktop-menu/components/desktop-menu-skeleton'
import { useMenuItems } from 'shared/components/header/hooks/use-menu-items'
import useUser from 'shared/hooks/use-user'

export function DesktopMenu() {
  const { user, checkAdminRoles, isWorkspaceOwner, userStatus, isLoading } = useUser()
  const { getMenuItems, upgradeAccountItem } = useMenuItems()
  if (isLoading) {
    return <DesktopMenuSkeleton />
  } else if (!user) {
    return null
  }

  return (
    <div className={`hidden h-full lg:flex items-center ml-auto gap-4`}>
      {user?.userPlanId === 40 && isWorkspaceOwner && !user?.impersonator && (
        <DesktopMenuItem item={upgradeAccountItem} />
      )}
      {getMenuItems(userStatus).map((item, idx) => {
        if (item.item.isAdmin && !checkAdminRoles()) {
          return
        }
        return <DesktopMenuItem key={idx} item={item.item} />
      })}
      <NotificationMenu />
    </div>
  )
}
