export const getBorderColor = (type: string) => {
  switch (type) {
    case 'success':
      return 'border-green'
    case 'error':
      return 'border-danger'
    default:
      return 'border-blue'
  }
}

export const getCloseIconColors = (type: string) => {
  switch (type) {
    case 'success':
      return 'hover:fill-green group-focus-visible:fill-green'
    case 'error':
      return 'hover:fill-danger group-focus-visible:fill-danger'
    default:
      return 'hover:fill-blue group-focus-visible:fill-blue'
  }
}
