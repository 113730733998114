import React from 'react'

function UpgradeIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      id="&#x56FE;&#x5C42;_1"
      height="24"
      viewBox="0 0 44 44"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      fill={'#ACB7D1'}
      {...props}
    >
      <path d="m33.73 19.88h-6.64a1.85 1.85 0 0 0 -1.6.92 9.05 9.05 0 0 1 -1.71 2.2.73.73 0 0 0 .48 1.27h9.47a1.1 1.1 0 0 0 1.1-1.1v-2.17a1.1 1.1 0 0 0 -1.1-1.12zm3.67-8.06h-14.35a.73.73 0 0 0 -.67.45.72.72 0 0 0 .16.8l2.62 2.61a1.82 1.82 0 0 0 1.3.54h10.94a1.1 1.1 0 0 0 1.1-1.1v-2.2a1.1 1.1 0 0 0 -1.1-1.1zm-7.33 16.18h-9.57a.74.74 0 0 0 -.74.73v2.94a.74.74 0 0 0 .74.73h9.57a1.1 1.1 0 0 0 1.1-1.1v-2.2a1.1 1.1 0 0 0 -1.1-1.1zm-8.86-7.78h-4.89v13.51a1.1 1.1 0 0 1 -1.1 1.1h-2.94a1.1 1.1 0 0 1 -1.1-1.1v-13.56h-4.89a1.48 1.48 0 0 1 -1-2.51l7-6.95a2.17 2.17 0 0 1 1.56-.65 2.2 2.2 0 0 1 1.56.65l7 7a1.47 1.47 0 0 1 -1.06 2.51z" />
    </svg>
  )
}

export default UpgradeIcon
