import { UserRolesEnum } from '../enums/user-roles-enum'
import { UserInterface } from '../types/user-interface'

const hasAffiliateRole = (user: UserInterface) => {
  return user.roles.includes(UserRolesEnum.affiliate)
}

const hasCustomerRole = (user: UserInterface) => {
  return user.roles.includes(UserRolesEnum.customer)
}

export const getDefaultDashboardUrl = (user: UserInterface) => {
  if (hasCustomerRole(user)) return '/'
  if (hasAffiliateRole(user)) return '/affiliate'
  // it's a fallback, we shouldn't be there
  return '/'
}
