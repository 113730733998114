import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { PaymentStatusInterface } from 'shared/notifications/types/payment-status-interface'
import useSWR from 'swr'

export function useSwrPaymentNotifications() {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    PaymentStatusInterface
  >({
    method: RequestMethodsEnum.get,
  })
  const { isCustomer, user } = useUser()
  return useSWR(() => {
    if (!isCustomer || !user) return
    return `/api/dashboard/customer/payment/payment/notifications`
  }, fetcher)
}
