export enum UserRolesEnum {
  user = 'ROLE_USER',
  customer = 'ROLE_CUSTOMER',
  affiliate = 'ROLE_AFFILIATE',
  student = 'ROLE_STUDENT',
}

export enum AdminRolesEnum {
  contentManager = 'ROLE_CONTENT_MANAGER',
  support = 'ROLE_SUPPORT',
  admin = 'ROLE_ADMIN',
  superAdmin = 'ROLE_SUPER_ADMIN',
  mailingManager = 'ROLE_MAILING_MANAGER',
}

export enum UserStatusEnum {
  affiliate = 'affiliate',
  customer = 'customer',
  affiliate_student = 'affiliate_student',
}

export type UserRoleType = UserRolesEnum | AdminRolesEnum
export const userRoleLocoKeys: Record<UserRoleType, string> = {
  [UserRolesEnum.affiliate]: 'dashboard.user_role.title.affiliate',
  [UserRolesEnum.student]: 'dashboard.user_role.title.student',
  [UserRolesEnum.customer]: 'dashboard.user_role.title.customer',
  [UserRolesEnum.user]: 'dashboard.user_role.title.user',
  [AdminRolesEnum.contentManager]: 'dashboard.user_role.title.content_manager',
  [AdminRolesEnum.support]: 'dashboard.user_role.title.support',
  [AdminRolesEnum.admin]: 'dashboard.user_role.title.admin',
  [AdminRolesEnum.superAdmin]: 'dashboard.user_role.title.super_admin',
  [AdminRolesEnum.mailingManager]: 'dashboard.user_role.title.mailing_manager',
}
