import React from 'react'

function EmailsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 22"
      fill="#ACB8D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4753 8.47342C15.6279 8.27888 15.6086 8.00028 15.4304 7.82891C15.2521 7.65754 14.9731 7.6492 14.7849 7.80946L6.15544 15.1533L1.5985 13.3905C1.3327 13.2886 1.11625 13.0887 0.994021 12.8316C0.871542 12.5748 0.852573 12.2805 0.940996 12.01C1.02944 11.7395 1.21859 11.5135 1.46909 11.3785L20.3875 0.777433C20.5866 0.670209 20.8181 0.638952 21.0385 0.689664C21.2591 0.740155 21.4536 0.869374 21.586 1.05279C21.7185 1.23645 21.7796 1.46178 21.7583 1.6871L20.0988 18.9911C20.065 19.351 19.8635 19.6738 19.5552 19.8621C19.2466 20.0504 18.8675 20.0821 18.5321 19.9473L12.9861 17.7993L9.50435 21.0629C9.33211 21.2239 9.1036 21.3109 8.86793 21.3049C8.63202 21.2991 8.40813 21.2007 8.24424 21.0314C8.08011 20.8619 7.98935 20.6347 7.99097 20.3989V17.836L15.4753 8.47342Z"
      />
    </svg>
  )
}

export default EmailsIcon
