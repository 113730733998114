import React from 'react'

function BurgerIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M4 6h16M4 12h16M4 18h16" />
    </svg>
  )
}

export default BurgerIcon
