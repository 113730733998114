import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { MutatorCallback, mutate } from 'swr'
import { NOTIFICATIONS_API_URL } from '../api/notifications-api'
import { NotificationsApiInterface } from '../types/notification-types'

interface MutateAllNotificationInterface {
  mutator?: MutatorCallback<NotificationsApiInterface>
  matcher?: (key: string) => boolean
  shouldRevalidate?: boolean
}

export interface GetNotificationLinkResponse {
  link: string
}

export const useNotificationsActions = () => {
  const { fetcher: postFetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.post>({
    method: RequestMethodsEnum.post,
  })

  const { fetcher: linkFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    GetNotificationLinkResponse
  >({
    method: RequestMethodsEnum.get,
  })

  const markAllNotificationsAsRead = async () => {
    await postFetcher(`${NOTIFICATIONS_API_URL}/notifications/mark-all-as-read`)
    await globalReadNotificationUpdate()
  }

  const markNotificationAsRead = async (id: string) => {
    await postFetcher(`${NOTIFICATIONS_API_URL}/notifications/${id}/mark-as-read`)
    await globalReadNotificationUpdate(id)
  }

  const getNotificationLink = async (id: string) => {
    const data = await linkFetcher(`${NOTIFICATIONS_API_URL}/notifications/${id}`)
    await globalReadNotificationUpdate(id)

    return data
  }

  const mutateAllNotifications = ({
    mutator,
    matcher,
    shouldRevalidate = false,
  }: MutateAllNotificationInterface) =>
    mutate<NotificationsApiInterface>(
      (key: unknown) =>
        typeof key === 'string' &&
        key.startsWith(`${NOTIFICATIONS_API_URL}/notifications`) &&
        (matcher ? matcher(key) : true),
      mutator,
      shouldRevalidate,
    )

  const globalReadNotificationUpdate = async (id?: string) => {
    if (id) {
      await mutateAllNotifications({
        matcher: key => !key.includes('filter[onlyUnRead]=true'),
        mutator: data => {
          if (data && data.items) {
            return {
              items: data.items.map(item => (item.id === id ? { ...item, read: true } : item)),
              hasMore: data.hasMore,
            }
          }
        },
      })
    } else {
      await mutateAllNotifications({
        matcher: key => !key.includes('filter[onlyUnRead]=true'),
        mutator: data => {
          if (data && data.items) {
            return {
              items: data.items.map(item => ({ ...item, read: true })),
              hasMore: data.hasMore,
            }
          }
        },
      })
    }

    // fetch updated data for lists consisting of unreads
    await mutateAllNotifications({
      matcher: key => key.includes('filter[onlyUnRead]=true'),
      shouldRevalidate: true,
    })

    // update global hasUnread flag from API
    await mutate(`${NOTIFICATIONS_API_URL}/notifications/has-unread`)
  }

  return {
    markAllNotificationsAsRead,
    markNotificationAsRead,
    getNotificationLink,
    mutateAllNotifications,
    globalReadNotificationUpdate,
  }
}
