import { ErrorBoundary, Provider } from '@rollbar/react'
import { NextPage } from 'next'
import { SSRConfig, appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { Router } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import { rollbarConfig } from 'rollbar/constants'
import Toast from 'shared/components/toast'
import { avertaFont, avertaPeFont, interFont } from 'shared/constants/fonts'
import SWRCountProvider from 'shared/hooks/use-controlled-swr/use-swr-key-with-version'
import DashboardLayout from 'shared/layout/components/dashboard-layout'
import fixFailedToExecuteRemoveChildOnNode from 'shared/utils/fix-failed-to-execute-remove-child-on-node'
import { SWRConfig } from 'swr'
import '../../styles/chatbase-override.css'
import '../../styles/globals.css'

type NextPageWithLayout = NextPage<PageProps> & {
  getLayout: (page: ReactElement, browserTabTitle: PageProps['browserTabTitle']) => ReactNode
}

export interface PageProps {
  browserTabTitle: string
}

type AppPropsWithLayout = AppProps<PageProps> & {
  pageProps: SSRConfig
} & {
  Component: NextPageWithLayout
}

fixFailedToExecuteRemoveChildOnNode()

const getDefaultPageLayout = (
  page: ReactElement,
  browserTabTitle: PageProps['browserTabTitle'],
) => <DashboardLayout browserTabTitle={browserTabTitle}>{page}</DashboardLayout>

function App({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    const handleRouteStart = () => NProgress.start()
    const handleRouteDone = () => NProgress.done()

    Router.events.on('routeChangeStart', handleRouteStart)
    Router.events.on('routeChangeComplete', handleRouteDone)
    Router.events.on('routeChangeError', handleRouteDone)

    return () => {
      // Make sure to remove the event handler on unmount!
      Router.events.off('routeChangeStart', handleRouteStart)
      Router.events.off('routeChangeComplete', handleRouteDone)
      Router.events.off('routeChangeError', handleRouteDone)
    }
  }, [])

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? getDefaultPageLayout

  return (
    <>
      <style jsx global>{`
        :root {
          --font-inter: ${interFont.style.fontFamily};
          --font-averta: ${avertaFont.style.fontFamily};
          --font-avertape: ${avertaPeFont.style.fontFamily};
        }
      `}</style>
      <Script
        strategy="beforeInteractive"
        crossOrigin="anonymous"
        src="https://unpkg.com/date-time-format-timezone@latest/build/browserified/date-time-format-timezone-complete-min.js"
      />
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateIfStale: false,
              onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                if (retryCount > 3) {
                  if (error.status === 502 || error.status === 504) return
                }
              },
            }}
          >
            <SWRCountProvider>
              {getLayout(<Component {...pageProps} />, pageProps.browserTabTitle)}
              <Toast />
            </SWRCountProvider>
          </SWRConfig>
        </ErrorBoundary>
      </Provider>
    </>
  )
}

export default appWithTranslation(App)
