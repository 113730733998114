import { Float } from '@headlessui-float/react'
import { Menu } from '@headlessui/react'
import React from 'react'
import { LocaleEnum, localeLabels, localeNames } from 'shared/enums/locale-enum'
import { twMerge } from 'tailwind-merge'
import ChevronDownIcon from '../../icons/chevron-down-icon'

export interface LanguageSwitcherMenuBaseProps {
  buttonClassName?: string
  selectedLanguage: LocaleEnum
  listOfLanguages?: LocaleEnum[]
  isFetching?: boolean
  changeLanguage: (locale: LocaleEnum) => void
}

export function LanguageSwitcherMenuBase({
  buttonClassName,
  changeLanguage,
  selectedLanguage,
  listOfLanguages = Object.values(LocaleEnum),
  isFetching,
}: LanguageSwitcherMenuBaseProps) {
  return (
    <Menu>
      <Float
        as="div"
        className="relative"
        placement="bottom-end"
        flip
        portal
        enter="transition duration-300 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-175 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Button
          disabled={isFetching}
          className={`relative flex items-center gap-2 justify-between ${
            buttonClassName || ''
          } ${twMerge(isFetching && 'pointer-events-none')}`}
          data-test="language-switcher-button"
        >
          <span className="flex-1 text-start capitalize">
            {selectedLanguage && localeLabels[selectedLanguage]}
          </span>
          <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
        </Menu.Button>
        <Menu.Items
          className={`focus:outline-none max-h-[400px] overflow-hidden overflow-y-auto customScroll ${
            isFetching ? 'pointer-events-none' : ''
          }`}
        >
          <div
            className="flex flex-col py-1
              rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            {listOfLanguages.map(language => (
              <Menu.Button
                disabled={language === selectedLanguage}
                key={language}
                className={`px-4 py-3 
      text-left whitespace-normal font-normal main-transition-colors hover:text-blue focus-visible:text-blue ${
        language === selectedLanguage ? 'text-blue w-full flex items-center' : 'text-gray-300'
      }`}
                onClick={() => changeLanguage(language as LocaleEnum)}
                data-test={`language-switcher-option-button-${language}`}
              >
                {localeNames[language as LocaleEnum]}
              </Menu.Button>
            ))}
          </div>
        </Menu.Items>
      </Float>
    </Menu>
  )
}
