import React from 'react'

function FunnelsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 19"
      fill="#ACB7D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00462 13.1546H15.6384V18.1335C15.6384 18.6023 15.2623 18.981 14.7962 18.981H7.8468C7.38068 18.981 7.00461 18.6023 7.00461 18.1335L7.00462 13.1546ZM21.0702 0.0185547C21.7191 0.0185547 22.1243 0.725675 21.8012 1.28978L19.1872 5.84501H3.45578L0.844472 1.28978C0.518722 0.725675 0.923922 0.0185547 1.57278 0.0185547H21.0702H21.0702ZM4.43038 7.53998H18.2126L15.9641 11.4596H6.67886L4.43038 7.53998Z"
      />
    </svg>
  )
}

export default FunnelsIcon
