export type LimitType = 10 | 25 | 50

export enum OrderEnum {
  next = 'next',
  prev = 'prev',
}

export interface DataWithPaginationInterface<T> {
  hasMore: boolean
  items: T[]
}

export interface PaginationInterface {
  startFromId?: number | string
  order: OrderEnum
}

export interface CountInterface {
  count: number
}

export interface SwrPaginationInterface {
  limit?: LimitType
  pagination: PaginationInterface
}
