import { LocaleEnum } from 'shared/enums/locale-enum'

export const getAccountRestrictedLink = (locale: LocaleEnum) => {
  switch (locale) {
    case LocaleEnum.PORTUGUESE: {
      return 'https://help-pt.systeme.io/article/1117-o-que-acontece-quando-a-minha-conta-fica-restrita'
    }
    default: {
      return 'https://help.systeme.io/article/1086-what-happens-when-my-account-gets-restricted'
    }
  }
}
