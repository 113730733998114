import { twMerge } from 'tailwind-merge'

export interface UnreadIndicatorProps {
  className?: string
  iconClassName?: string
}

export default function UnreadIndicator({ className, iconClassName }: UnreadIndicatorProps) {
  return (
    <div className={twMerge('flex items-center p-1 text-xs rounded-full', className)}>
      <div className={twMerge('h-[5px] w-[5px] rounded-full bg-blue', iconClassName)} />
    </div>
  )
}
