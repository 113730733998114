import React from 'react'

const DesktopMenuSkeleton = () => (
  <div className="animate-pulse items-center lg:flex gap-4 hidden ml-auto">
    {Array.from(Array(7).keys()).map(index => (
      <div key={index} className="w-16 h-5 rounded bg-gray" />
    ))}
  </div>
)

export default DesktopMenuSkeleton
