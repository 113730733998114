import {
  ErrorResponseContentInterface,
  ErrorResponseInterface,
  ErrorResponseInterfaceNew,
} from '../types/error-response-content-interface'

export class BadRequest<F> extends Error {
  public errors: ErrorResponseContentInterface<F>

  constructor({ errors }: ErrorResponseInterface<F>) {
    super()
    this.errors = errors
  }
}

export class BadRequestNew<T> extends Error {
  public errors

  constructor({ errors }: ErrorResponseInterfaceNew<T>) {
    super()
    this.errors = errors
  }
}
