import { useEffect, useState } from 'react'
import { changeLanguage as changeLanguageUnAuthorizedUser } from 'shared/api/public-user-api'
import { changeLanguage } from 'shared/api/user-api'
import { addLanguageResource } from 'shared/components/language-switcher/utils/add-language-resource'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { LocalizeValues, useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'

export const useLanguageSwitcher = () => {
  const { i18n } = useLocoTranslation()
  const { dashboardUser, publicUser, mutate: mutateUser, mutatePublic } = useUser()
  const [isChangingLanguage, setIsChangingLanguage] = useState(false)
  useEffect(() => {
    const loadTranslations = async () => {
      if (!dashboardUser && !publicUser) return
      const locale =
        dashboardUser?.dashboardLocale || publicUser?.dashboardLocale || LocaleEnum.ENGLISH
      if (locale !== LocaleEnum.ENGLISH) {
        for await (const ns of i18n.options.ns as LocalizeValues[]) {
          await addLanguageResource(locale, ns, i18n)
        }
        await i18n.changeLanguage(locale)
      }
    }
    loadTranslations()
  }, [dashboardUser, i18n, publicUser])

  const handleChangeLanguage = async (locale: LocaleEnum) => {
    try {
      setIsChangingLanguage(true)
      if (dashboardUser) {
        await changeLanguage(locale)
      } else if (publicUser) {
        await changeLanguageUnAuthorizedUser(locale)
      }
      await i18n.changeLanguage(locale)
      await mutateUser(
        () =>
          // due to fallback data in useSWR, we need to use data directly, not from mutate
          dashboardUser && {
            ...dashboardUser,
            dashboardLocale: locale,
          },
        false,
      )
      await mutatePublic(
        () =>
          dashboardUser && {
            ...dashboardUser,
            dashboardLocale: locale,
          },
        false,
      )
    } catch (e) {
      console.log(e)
    } finally {
      setIsChangingLanguage(false)
    }
  }
  return { handleChangeLanguage, isChangingLanguage }
}
