import { Float } from '@headlessui-float/react'
import { Menu } from '@headlessui/react'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { setWorkspace } from 'shared/api/workspace-change-api'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'
import { useSWRConfig } from 'swr'

interface WorkspaceSelectorProps {
  position: 'top-menu' | 'side-menu'
  closeMenu: () => void
}

interface WorkspaceInterface {
  id: number | null
  workspaceId: number | null
  name: string
  avatarUrl: string
}

function WorkspaceSelector({ position, closeMenu }: WorkspaceSelectorProps) {
  const { user, mutate, isWorkspaceOwner } = useUser()
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<Omit<WorkspaceInterface, 'workspaceId'>>()
  const [workspaces, setWorkspaces] = useState<WorkspaceInterface[]>([])
  const { pathname } = useRouter()

  const dropdownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const router = useRouter()
  const { cache } = useSWRConfig()
  const { t } = useLocoTranslation()
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (!user) {
      setWorkspaces([])
      return
    }
    if (user.workspaceMemberships && user.workspaceMemberships.length !== 0) {
      setWorkspaces([
        {
          id: user.id,
          workspaceId: null,
          name: user.subdomain,
          avatarUrl: user.avatarUrl,
        },
        ...user.workspaceMemberships.map(el => ({
          id: el.id,
          workspaceId: el.id,
          name: el.name,
          avatarUrl: el.image,
        })),
      ])
    }
  }, [user])

  useEffect(() => {
    if (!user) {
      setSelectedWorkspace(undefined)
      return
    }
    if (
      user.workspaceMemberships &&
      user.workspaceMemberships.length !== 0 &&
      workspaces.length !== 0
    ) {
      isWorkspaceOwner
        ? setSelectedWorkspace({
            name: user.subdomain,
            id: user.id,
            avatarUrl: user.avatarUrl,
          })
        : setSelectedWorkspace(() => {
            const el = user.workspaceMemberships?.find(el => el.ownerId === user.workspaceOwner)
            if (el) {
              return {
                name: el.name,
                id: el.id,
                avatarUrl: el.image,
              }
            }
          })
    }
  }, [workspaces, user, isWorkspaceOwner])

  const onWorkspaceChange = async (workspace: WorkspaceInterface) => {
    const mainDashboardPath = '/'
    try {
      setIsFetching(true)
      NProgress.start()
      await setWorkspace({ currentWorkspaceMembership: workspace.workspaceId })
      closeMenu()
      // @ts-ignore
      await cache.clear()
      if (pathname === mainDashboardPath) {
        await mutate()
      } else {
        await router.push(mainDashboardPath)
      }
      NProgress.done()
      setIsFetching(false)
      toast.success(t('dashboard.notifications.workspace_saved'))
    } catch (e) {
      setIsFetching(false)
      NProgress.done()
    }
  }

  return selectedWorkspace ? (
    <div className={`${position === 'top-menu' && 'hidden lg:flex'}`}>
      <Menu>
        <Float
          as={'div'}
          className="relative"
          placement={position === 'top-menu' ? 'bottom-end' : 'top-end'}
          flip
          portal
          onUpdate={() => {
            if (dropdownRef.current && buttonRef.current) {
              const buttonWidth = buttonRef.current.getBoundingClientRect().width
              const dropdownWidth = dropdownRef.current.getBoundingClientRect().width
              if (dropdownWidth < buttonWidth) {
                dropdownRef.current.style.width = `${buttonWidth}px`
              }
            }
          }}
          offset={10}
          enter="transition duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-175 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <Menu.Button
              className={`relative grid grid-flow-col items-center w-full gap-2 justify-between rounded-md bg-transparent border border-gray/30 px-4 py-2 text-sm font-medium ${
                position === 'top-menu' ? 'text-gray' : 'text-darkblue'
              } disabled:bg-gray-300/50 disabled:animate-pulse`}
              as={'button'}
              ref={buttonRef}
              disabled={isFetching}
            >
              <img
                className="h-7 w-7 rounded-full object-cover"
                src={selectedWorkspace.avatarUrl}
                alt={`${selectedWorkspace.name}`}
              />
              <span className="text-start truncate">{selectedWorkspace.name}</span>
              <ChevronDownIcon
                className={`h-3 w-3 ${position === 'side-menu' && 'rotate-180'}`}
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Menu.Items
            className={`w-max rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div ref={dropdownRef} className="px-1 py-1 max-h-[300px] overflow-y-auto">
              {workspaces.map(el => {
                if (el.id === selectedWorkspace.id) return null
                return (
                  <Menu.Item key={el.id}>
                    {({ active }) => (
                      <button
                        className={`outline-none relative gap-3 
                         group flex w-full ${
                           active ? 'bg-blue text-white' : 'text-darkblue'
                         } items-center rounded-md px-3 py-1 text-sm text-start`}
                        onClick={() => onWorkspaceChange(el)}
                      >
                        <img
                          className="h-7 w-7 rounded-full object-cover"
                          src={el.avatarUrl}
                          alt={`${el.name}`}
                        />
                        {el.name}
                      </button>
                    )}
                  </Menu.Item>
                )
              })}
            </div>
          </Menu.Items>
        </Float>
      </Menu>
    </div>
  ) : null
}

export default WorkspaceSelector
