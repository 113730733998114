import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import EmptyFolderIcon from 'shared/icons/empty-folder-icon'

const NotificationEmptyList = () => {
  const { t } = useLocoTranslation()

  return (
    <div className="flex w-full justify-center items-center flex-col gap-3 py-20 bg-white rounded-b-lg">
      <EmptyFolderIcon height={100} width={100} />
      <div className={`flex flex-col items-center`}>
        <span className={'font-medium text-darkblue text-base'}>
          {t('dashboard.notifications.empty_list.title')}
        </span>
        <span className={'text-darkblue text-base'}>
          {t('dashboard.notifications.empty_list.description')}
        </span>
      </div>
    </div>
  )
}

export default NotificationEmptyList
