import React from 'react'

function AutomationsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 22"
      fill="#ACB8D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.869867 10.956C0.869867 5.18211 5.55026 0.501719 11.3242 0.501719C17.0981 0.501719 21.7785 5.18211 21.7785 10.956C21.7785 16.7299 17.0981 21.4103 11.3242 21.4103C5.55026 21.4103 0.869867 16.7299 0.869867 10.956Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63731 9.6693L13.0481 5.91566C13.8419 5.3615 14.3015 5.67518 14.0717 6.6162L13.0794 10.6312L14.8029 11.0599C15.5863 11.2586 15.6803 11.7918 15.0223 12.2519L9.61151 16.0055C8.81766 16.5597 8.35805 16.246 8.58785 15.305L9.58017 11.29L7.85667 10.8613C7.07325 10.6626 6.97925 10.1293 7.63731 9.6693Z"
        fill="white"
      />
    </svg>
  )
}

export default AutomationsIcon
