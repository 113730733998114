import { i18n as i18nGlobal } from 'next-i18next'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { LocalizeValues } from 'shared/hooks/use-loco-translation'

export const addLanguageResource = async (
  locale: LocaleEnum,
  ns: LocalizeValues,
  i18n: typeof i18nGlobal,
) => {
  const additionalTranslations = await require(`/public/locales/${locale}/${ns}.json`)
  i18n?.addResourceBundle(locale, ns, additionalTranslations, true, true)
}
