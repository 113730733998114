import React from 'react'

function SettingsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="#CBD5E1"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2081 19.565C12.6186 19.565 12.0637 19.2471 11.7598 18.7358L11.6494 18.5464C11.2143 17.7871 10.6315 17.3692 10.0083 17.3692C9.38512 17.3692 8.79943 17.7871 8.35924 18.5464L8.24999 18.7358C7.94591 19.2471 7.39074 19.565 6.80102 19.565C6.50817 19.565 6.22045 19.4858 5.96923 19.3364L4.23962 18.3467C3.33422 17.8252 3.02002 16.6631 3.5393 15.7559C3.97936 14.9971 4.05065 14.2817 3.73986 13.7427C3.42907 13.2031 2.77563 12.9062 1.90002 12.9062C0.85229 12.9062 0 12.0542 0 11.0063V9.2461C0 8.19923 0.85229 7.34717 1.90002 7.34717C2.77539 7.34717 3.42883 7.0503 3.73974 6.51074C4.05053 5.9707 3.97936 5.25537 3.5393 4.49658C3.2871 4.05957 3.22143 3.54834 3.35424 3.05615C3.48693 2.56494 3.80138 2.15625 4.23962 1.90576L5.96923 0.9165C6.22094 0.7666 6.50841 0.6875 6.80077 0.6875C7.39025 0.6875 7.94555 1.00537 8.24999 1.5166L8.35924 1.70703C8.79442 2.46631 9.37743 2.88428 10.0008 2.88428C10.6243 2.88428 11.2097 2.46631 11.6494 1.70703L11.7598 1.5166C12.0642 1.00537 12.6194 0.6875 13.2086 0.6875C13.5008 0.6875 13.7881 0.7666 14.0394 0.9165L15.7701 1.90576C16.2085 2.15625 16.5229 2.56543 16.6555 3.05664C16.7881 3.54834 16.7219 4.05957 16.4693 4.49658C16.0295 5.25586 15.9587 5.9707 16.27 6.51074C16.5809 7.05029 17.2344 7.34717 18.1097 7.34717C19.1574 7.34717 20.0097 8.19922 20.0097 9.2461V11.0063C20.0097 12.0542 19.1574 12.9062 18.1097 12.9062C17.2341 12.9062 16.5807 13.2031 16.2699 13.7427C15.9588 14.2817 16.0298 14.9971 16.4693 15.7559C16.9896 16.666 16.676 17.8281 15.7701 18.3467L14.0394 19.3364C13.7878 19.4858 13.5003 19.565 13.2081 19.565ZM9.99914 6.875C8.20703 6.875 6.74902 8.33301 6.74902 10.125C6.74902 11.9175 8.20703 13.3755 9.99914 13.3755C11.7913 13.3755 13.2493 11.9175 13.2493 10.125C13.2493 8.33301 11.7913 6.875 9.99914 6.875Z"
      />
    </svg>
  )
}

export default SettingsIcon
