import { MenuItemInterface } from 'shared/components/header/types/menu-interface'
import useUser from 'shared/hooks/use-user'

export const useHideItem = () => {
  const { checkAdminRoles, isWorkspaceOwner, isWsAssistant, user } = useUser()
  return (item: MenuItemInterface['item']): boolean => {
    if (item.adminRoles && !checkAdminRoles(item.adminRoles)) {
      return true
    }

    if (!isWorkspaceOwner && item.hideForWorkspace) {
      return true
    }

    if (isWsAssistant && item.hideForWorkspaceAssistant) {
      return true
    }

    if (item.hideForRoles && item.hideForRoles.some(role => user?.roles.includes(role))) {
      return true
    }

    return false
  }
}
