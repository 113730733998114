import { GetFilterQueryString, getFilterQueryString } from './get-filter-query-string'
import {
  GetPaginationQueryStringProps,
  getPaginationQueryString,
} from './get-pagination-query-string'

export type GetPaginationAndFilterQueryStringProps<T> = GetPaginationQueryStringProps &
  GetFilterQueryString<T>

export const getPaginationAndFilterQueryString = <
  T extends Record<string, string | number | boolean | null | undefined | number[]>,
>({
  filter,
  pagination,
  limit,
}: GetPaginationAndFilterQueryStringProps<T>) =>
  `${getPaginationQueryString({ pagination, limit })}${getFilterQueryString({
    filter,
  })}`
