import React from 'react'

function HelpIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 22"
      fill="#ACB8D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.869867 10.9274C0.869867 5.15351 5.55026 0.473122 11.3242 0.473122C17.0981 0.473122 21.7785 5.15351 21.7785 10.9274C21.7785 16.7013 17.0981 21.3817 11.3242 21.3817C5.55026 21.3817 0.869867 16.7013 0.869867 10.9274Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1975 12.8669C10.9228 12.8669 10.6604 12.7501 10.4774 12.5465C10.2946 12.3431 10.2057 12.0701 10.2336 11.7977C10.2613 11.252 10.523 10.826 10.7376 10.5647C11.0523 10.1809 11.4523 9.92014 11.6096 9.82584C12.057 9.56003 12.2495 9.17438 12.1378 8.76777C12.0212 8.34333 11.6082 8.02326 11.1771 8.02326C10.9567 8.02326 10.6358 8.10724 10.3595 8.50733C10.1919 8.81736 9.86876 9.01224 9.51626 9.01584L9.50642 9.0159C9.15736 9.0159 8.83386 8.82687 8.66216 8.52261C8.4886 8.21505 8.49618 7.83743 8.68195 7.53708C9.19944 6.6402 10.1639 6.08301 11.199 6.08301C11.4528 6.08301 11.7063 6.11655 11.9526 6.18262C13.2208 6.52213 14.1071 7.67682 14.108 8.99063C14.108 9.95065 13.5643 10.8563 12.6537 11.4134C12.4017 11.5674 12.3087 11.8271 12.2187 12.0783C12.0798 12.4661 11.9362 12.8669 11.1975 12.8669ZM11.1975 15.7748C10.663 15.7748 10.2282 15.34 10.2282 14.8055C10.2282 14.2711 10.663 13.8362 11.1975 13.8362C11.7319 13.8362 12.1667 14.271 12.1667 14.8055C12.1667 15.34 11.7319 15.7748 11.1975 15.7748Z"
        fill="white"
      />
    </svg>
  )
}

export default HelpIcon
