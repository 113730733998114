import React from 'react'

function ArrowLeftIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.5 8.5H2.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 4L2.5 8.5L7 13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowLeftIcon
