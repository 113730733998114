import axios, { AxiosRequestConfig } from 'axios'
import { ConflictError } from 'shared/errors/conflict-error'
import { InvalidTokenError, invalidTokenErrorMessages } from 'shared/errors/invalid-token-error'
import { NetworkError } from 'shared/errors/network-error'
import { PayloadTooLarge } from 'shared/errors/payload-too-large'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'
import { AccessDenied } from '../errors/access-denied'
import { BadRequest } from '../errors/bad-request'
import { InternalError } from '../errors/internal-error'
import { NotFound } from '../errors/not-found'
import { UnauthorizedResponse } from '../errors/unauthorized-response'

const httpClient = axios.create({
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

httpClient.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      throw error
    }

    if (error.response) {
      if (error.response.status === 400) {
        throw new BadRequest(error.response.data)
      } else if (error.response.status === 409) {
        throw new ConflictError(error.response.data)
      } else if (error.response.status === 422) {
        throw new UnprocessableContentError(error.response.data)
      } else if (error.response.status === 401) {
        if (invalidTokenErrorMessages.includes(error.response.data.message)) {
          throw new InvalidTokenError()
        } else if (error.response.data.location) {
          throw new UnauthorizedResponse(error.response.data.location)
        } else {
          ;(window as any).Rollbar.error('Unauthorized response', error.response.data)
        }
      } else if (error.response.status === 403) {
        throw new AccessDenied()
      } else if (error.response.status === 404) {
        throw new NotFound()
      } else if (error.response.status === 413) {
        throw new PayloadTooLarge()
      } else if (error.response.status === 500 || error.response.status === 502) {
        throw new InternalError()
      } else if (error.code === 'ERR_NETWORK') {
        throw new NetworkError()
      }
    }
  },
)

export const baseFetcher = (url: string, config?: AxiosRequestConfig) =>
  httpClient
    .get(url, config)
    .then(res => res.data)
    .catch(e => {
      throw e
    })

export default httpClient
