import { LimitType, PaginationInterface } from 'shared/types/pagination-interface'

export const getPaginationQuery = ({
  limit,
  pagination,
}: {
  limit: LimitType
  pagination: PaginationInterface
}) => {
  const newQuery: Record<string, string> = {}
  if (limit !== 10) {
    newQuery['limit'] = limit.toString()
  }
  if (pagination.startFromId) {
    newQuery['startFromId'] = pagination.startFromId.toString()
  }
  if (pagination.order === 'prev') {
    newQuery['order'] = pagination.order
  }
  return newQuery
}
