import { Placement } from '@floating-ui/core/src/types'
import { Float } from '@headlessui-float/react'
import React, { ReactNode, useRef } from 'react'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import { useIsOverflowText } from 'shared/hooks/use-is-overflow-text'
import useWindowSize from 'shared/hooks/use-window-size'

export interface TooltipProps {
  children: ReactNode
  mode?: 'click' | 'hover' | 'overflow'
  label?: string | JSX.Element
  position?: Placement
  className?: string
  wrapperClassName?: string
  tooltipClassName?: string
  stayOnHover?: boolean
  disabled?: boolean
  portal?: boolean
}

export const Tooltip = ({
  children,
  mode = 'hover',
  label,
  position,
  className,
  wrapperClassName,
  tooltipClassName,
  stayOnHover,
  disabled,
  portal,
}: TooltipProps) => {
  const targetRef = useRef(null)
  const { width } = useWindowSize()
  const { show, delayOpen, delayClose } = useFloatHover()

  const isOverflow = useIsOverflowText(targetRef)

  return !!label ? (
    <Float
      portal={portal}
      show={show && !disabled}
      placement={position || 'top'}
      offset={16}
      flip={{
        fallbackPlacements: ['top', 'bottom', 'left', 'right'],
        fallbackStrategy: 'initialPlacement',
      }}
      arrow
      as={'div'}
      enter="transition duration-100 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-75 ease-in"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      ref={targetRef}
      className={wrapperClassName}
    >
      <div
        onMouseEnter={delayOpen}
        onMouseLeave={delayClose}
        className={`relative truncate items-center ${
          className ? className : 'w-full max-w-fit h-full '
        } ${mode === 'overflow' ? 'overflow-hidden' : 'flex'}`}
      >
        {children}
      </div>
      <div
        onMouseEnter={stayOnHover ? delayOpen : () => {}}
        onMouseLeave={stayOnHover ? delayClose : () => {}}
        className={`shadow-3xl rounded-lg ${show ? 'z-50' : 'z-0'} ${width < 1024 && 'invisible'} ${
          mode === 'overflow' ? (isOverflow ? 'visible' : 'invisible') : 'visible'
        } ${tooltipClassName || ''}`}
      >
        <Float.Arrow className="absolute bg-white w-5 h-5 rotate-45 border border-white" />
        <div className={`relative whitespace-normal w-fit px-5 py-3 bg-white rounded-lg`}>
          <div className="w-full text-center text-gray-300 font-medium text-sm font-averta break-normal">
            {label}
          </div>
        </div>
      </div>
    </Float>
  ) : null
}
