import { Float } from '@headlessui-float/react'
import { Menu } from '@headlessui/react'
import { Fragment } from 'react'
import ItemCount from 'shared/components/item-count'
import {
  ActionMenuItem,
  ActionMenuItemProps,
} from 'shared/components/table/components/action-menu-item'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import DotsIcon from 'shared/icons/dots-icon'
import { dateTimeFormat } from 'shared/utils/date-time-format'
import { twMerge } from 'tailwind-merge'
import { getNotificationIcon } from '../constants/notification-icons'
import { useNotificationsActions } from '../hooks/use-notification-actions'
import { NotificationItemInterface } from '../types/notification-types'

export interface NotificationItemProps {
  notification: NotificationItemInterface
  actions?: ActionMenuItemProps[]
}

const NotificationItem = ({ notification, actions }: NotificationItemProps) => {
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const { id, eventType, message, read, createdAt } = notification
  const { show, delayClose, delayOpen } = useFloatHover()
  const { getNotificationLink } = useNotificationsActions()

  const handleNotificationClick = async () => {
    const { link } = await getNotificationLink(id)
    window.open(link, '_blank')
  }

  return (
    <div
      className={twMerge(
        'group transition flex flex-row justify-between px-6 py-4 bg-white border-b border-gray last:border-b-0 text-darkblue hover:bg-blue-100 hover:cursor-pointer',
        read && 'text-gray group-hover:text-darkblue',
      )}
    >
      <div className="inline-flex flex-row gap-4 items-center" onClick={handleNotificationClick}>
        {getNotificationIcon(eventType)}
        <div className="pr-3">
          <div>
            <span
              className="group-hover:text-darkblue transition"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
          {user?.id !== undefined && (
            <div className="group-hover:text-darkblue transition">
              {dateTimeFormat(createdAt, {
                timeFormat: user.timeFormat,
                dateFormat: user.dateFormat,
                timeZone: user.timezone,
              })}
            </div>
          )}
        </div>
      </div>
      <div className="inline-flex flex-row gap-4 items-center">
        {!read && <ItemCount>{t('dashboard.notifications.new')}</ItemCount>}
        {actions && actions.length > 0 && (
          <Menu>
            {({ open }) => (
              <Float
                as={Fragment}
                show={show || open}
                enter="transition duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition duration-75 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                portal
                autoPlacement={{
                  alignment: 'start',
                }}
                offset={4}
                tailwindcssOriginClass={true}
              >
                <Menu.Button
                  onMouseEnter={delayOpen}
                  onMouseLeave={delayClose}
                  onClick={show ? delayClose : delayOpen}
                >
                  <DotsIcon />
                </Menu.Button>

                <Menu.Items
                  onMouseEnter={delayOpen}
                  onMouseLeave={delayClose}
                  className="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden focus:outline-none"
                >
                  {actions.map(action => (
                    <ActionMenuItem
                      key={`${action.label}-na-action`}
                      label={action.label}
                      onClick={action.onClick}
                    />
                  ))}
                </Menu.Items>
              </Float>
            )}
          </Menu>
        )}
      </div>
    </div>
  )
}

export default NotificationItem
