import { MAILING_API } from 'shared/components/dashboard-mail-editor/api/email-api'
import { MailingStatusEnum } from 'shared/enums/mailing-status-enum'
import useUser from 'shared/hooks/use-user'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

export const useSwrEmailStatusNotification = (shouldFetch: boolean) => {
  const { user } = useUser()
  return useSWR<{ mailingStatus: MailingStatusEnum }>(() => {
    if (!user || !shouldFetch) return
    return `${MAILING_API}/mailing-options`
  }, baseFetcher)
}
