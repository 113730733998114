import React from 'react'

function EmptyFolderIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="189"
      height="176"
      id="Layer_1"
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <circle cx="32.5" cy="32.5" fill="#eaebf0" r="32" />
        </g>
        <g>
          <path
            d="m49.1 22.9v1.2h-8.1c-1.1 0-2.1.7-2.3 1.5s-1.3 1.5-2.3 1.5h-16.1c-1.5 0-3 .9-3.3 2.1l-4.5 17.3c-.1.5 0 1 .2 1.3-.5-.5-.8-1.3-.8-2.1v-26.4c0-1.7 1.4-3.1 3.1-3.1h11.3c1.1 0 1.9.9 1.9 1.9s.9 1.9 1.9 1.9h16.1c1.6.1 2.9 1.3 2.9 2.9z"
            fill="#cccbdd"
          />
          <path
            d="m12.7 48.5c-.2 0-.4-.1-.5-.2-.7-.7-1-1.6-1-2.6v-26.4c0-2.1 1.7-3.9 3.9-3.9h11.3c1.5 0 2.7 1.2 2.7 2.7 0 .7.5 1.2 1.2 1.2h16.1c1.9 0 3.5 1.6 3.5 3.5v1.2c0 .4-.3.8-.8.8h-8.1c-.8 0-1.5.5-1.6.9-.3 1.1-1.6 2-3.1 2h-16c-1.3 0-2.4.8-2.6 1.5l-4.5 17.3c-.1.3 0 .5.1.7.2.3.1.8-.2 1-.1.3-.2.3-.4.3zm2.3-31.6c-1.3 0-2.4 1.1-2.4 2.4v23.6l3.6-13.9c.4-1.5 2.2-2.7 4-2.7h16.1c.8 0 1.5-.5 1.6-.9.3-1.1 1.6-2 3-2h7.3v-.5c0-1.1-.9-2-2-2h-16c-1.5 0-2.7-1.2-2.7-2.7 0-.7-.5-1.2-1.2-1.2h-11.3z"
            fill="#47769d"
          />
        </g>
        <g>
          <path
            d="m54.8 26.5-5.2 20c-.3 1.3-2 2.4-3.7 2.4h-30.9c-.1 0-.2 0-.3 0-.9-.1-1.6-.4-2-1-.3-.4-.3-.8-.2-1.3l4.5-17.5c.3-1.2 1.8-2.1 3.3-2.1h16.1c1.1 0 2.1-.7 2.3-1.5s1.3-1.5 2.3-1.5h11.3c1.8.1 2.9 1.2 2.5 2.5z"
            fill="#f5fdff"
          />
          <path
            d="m45.9 49.6h-30.9c-.1 0-.3 0-.4 0-1.1-.1-2-.6-2.5-1.4-.4-.6-.5-1.2-.3-1.9l4.4-17.3c.4-1.5 2.2-2.7 4-2.7h16.1c.8 0 1.5-.5 1.6-.9.3-1.1 1.6-2 3-2h11.3c1.2 0 2.2.4 2.8 1.2.5.6.6 1.3.4 2.1l-5.2 20c-.2 1.6-2.2 2.9-4.3 2.9zm-25.6-21.8c-1.3 0-2.4.8-2.6 1.5l-4.5 17.3c-.1.3 0 .5.1.7.2.4.8.6 1.4.7h.3 30.9c1.4 0 2.8-.8 3-1.8l5.2-20c.1-.3 0-.6-.2-.8-.3-.4-.9-.6-1.6-.6h-11.3c-.8 0-1.5.5-1.6.9-.3 1.1-1.6 2-3.1 2h-16z"
            fill="#47769d"
          />
        </g>
      </g>
    </svg>
  )
}

export default EmptyFolderIcon
