import { isAttachmentFile } from 'modules/profile/mailing-settings/utils/file-utils'
import {
  AttachmentFileApiInterface,
  AttachmentFileInterface,
  EmailId,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import httpClient from 'shared/utils/http-client'

export const MAILING_API = '/api/dashboard/customer/emails'

export const MAILING_RECIPIENT_API = '/api/dashboard/customer/email-recipients'

export function updateAttachments(emailId: EmailId, files: (AttachmentFileInterface | File)[]) {
  const formData: Record<string, any> = {}

  if (files.length > 0) {
    files.forEach((file, index) => {
      if (isAttachmentFile(file)) {
        formData[`attachments[${index}][id]`] = file.id
      } else {
        formData[`attachments[${index}][file][file][file]`] = file
      }
    })
  } else {
    return httpClient.post<AttachmentFileApiInterface>(`${MAILING_API}/${emailId}/attachments`, {
      attachments: null,
    })
  }

  return httpClient.postForm<AttachmentFileApiInterface>(
    `${MAILING_API}/${emailId}/attachments`,
    formData,
  )
}

export function updateAttachmentsWithRecipient(
  emailId: EmailId,
  files: (AttachmentFileInterface | File)[],
) {
  const formData: Record<string, any> = {}

  if (files.length > 0) {
    files.forEach((file, index) => {
      if (isAttachmentFile(file)) {
        formData[`attachments[${index}][id]`] = file.id
      } else {
        formData[`attachments[${index}][file][file][file]`] = file
      }
    })
  } else {
    return httpClient.post<AttachmentFileApiInterface>(
      `${MAILING_RECIPIENT_API}/${emailId}/attachments`,
      {
        attachments: null,
      },
    )
  }

  return httpClient.postForm<AttachmentFileApiInterface>(
    `${MAILING_RECIPIENT_API}/${emailId}/attachments`,
    formData,
  )
}
