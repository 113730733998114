import React from 'react'

function BlogsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19"
      height="28"
      viewBox="0 0 19 21"
      fill="#ACB7D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.51767 13.8557C4.2928 13.8557 4.07714 13.945 3.91814 14.104C3.75913 14.2631 3.6698 14.4787 3.6698 14.7036C3.6698 14.9284 3.75913 15.1441 3.91814 15.3031C4.07715 15.4621 4.29281 15.5514 4.51767 15.5514H14.1269C14.3517 15.5514 14.5674 15.4621 14.7264 15.3031C14.8854 15.1441 14.9747 14.9284 14.9747 14.7036C14.9747 14.4787 14.8854 14.263 14.7264 14.104C14.5674 13.945 14.3517 13.8557 14.1269 13.8557H4.51767ZM3.6698 10.1816C3.6698 10.6496 4.04965 11.0295 4.51767 11.0295H14.1269C14.3517 11.0295 14.5674 10.9401 14.7264 10.7811C14.8854 10.6221 14.9747 10.4065 14.9747 10.1816C14.9747 9.95673 14.8854 9.74107 14.7264 9.58207C14.5674 9.42306 14.3517 9.33373 14.1269 9.33373H4.51767C4.2928 9.33373 4.07714 9.42306 3.91814 9.58207C3.75913 9.74108 3.6698 9.95674 3.6698 10.1816ZM4.51767 4.81176C4.2928 4.81176 4.07714 4.90109 3.91814 5.0601C3.75913 5.21911 3.6698 5.43477 3.6698 5.65963C3.6698 5.8845 3.75913 6.10016 3.91814 6.25916C4.07715 6.41817 4.29281 6.5075 4.51767 6.5075H14.1269C14.3517 6.5075 14.5674 6.41817 14.7264 6.25916C14.8854 6.10015 14.9747 5.88449 14.9747 5.65963C14.9747 5.43476 14.8854 5.2191 14.7264 5.0601C14.5674 4.90109 14.3517 4.81176 14.1269 4.81176H4.51767ZM15.8226 0.298828C16.4671 0.298748 17.0877 0.543348 17.5588 0.983198C18.0299 1.42305 18.3165 2.02534 18.3606 2.66835L18.3662 2.84245V18.1018C18.366 18.7462 18.1213 19.3664 17.6815 19.8373C17.2416 20.3082 16.6395 20.5946 15.9967 20.6387L15.8226 20.6443H2.82193C2.17741 20.6444 1.55688 20.3998 1.08577 19.96C0.61466 19.5201 0.32809 18.9178 0.28397 18.2748L0.27832 18.1018V2.84245C0.27824 2.19793 0.52284 1.5774 0.96269 1.10629C1.40254 0.635178 2.00483 0.348608 2.64784 0.304488L2.82194 0.298838L15.8226 0.298828Z"
      />
    </svg>
  )
}

export default BlogsIcon
