import { Float } from '@headlessui-float/react'
import { Popover } from '@headlessui/react'
import React from 'react'
import { useLanguageSwitcher } from 'shared/components/language-switcher/hooks/use-language-switcher'
import { LocaleEnum, localeLabels, localeNames } from 'shared/enums/locale-enum'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from '../../hooks/use-user'
import ChevronDownIcon from '../../icons/chevron-down-icon'

export function DesktopLanguageSwitcher() {
  const { i18n } = useLocoTranslation()
  const { isLoading } = useUser()
  const { show, delayClose, delayOpen } = useFloatHover()
  const { handleChangeLanguage, isChangingLanguage } = useLanguageSwitcher()

  return isLoading || !i18n ? (
    <div className="h-6 w-[44px] animate-pulse rounded-sm bg-gray" />
  ) : (
    <Popover className={'h-full'}>
      <Float
        show={show}
        placement="bottom"
        offset={-5}
        arrow={5}
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={'h-full flex items-center'}
      >
        <Popover.Button
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          disabled={isChangingLanguage}
          className={`h-full flex items-center justify-around gap-2 focus:outline-none border-b-4 border-transparent focus-visible:border-blue ${
            isChangingLanguage && 'animate-pulse'
          }`}
          data-test="language-switcher-button"
        >
          <div className="w-5 text-sm font-bold text-white/90">
            {i18n?.language && localeLabels[i18n.language as LocaleEnum]}
          </div>
          <ChevronDownIcon />
        </Popover.Button>

        <Popover.Panel
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          className={`max-h-[400px] overflow-hidden overflow-y-auto customScroll rounded-lg bg-white shadow-lg z-50 ${
            isChangingLanguage ? 'pointer-events-none' : ''
          }`}
        >
          <Float.Arrow className={`absolute bg-white w-4 h-4 rotate-45`} />
          <ul className={'flex flex-col whitespace-nowrap text-gray-300 text-sm py-4'}>
            {Object.keys(localeLabels).map(dashboardLocale => (
              <li key={dashboardLocale}>
                <button
                  disabled={dashboardLocale === i18n.language}
                  className={`w-full text-start px-7 py-1 hover:text-blue focus:outline-none focus-visible:text-blue main-transition-colors ${
                    dashboardLocale === i18n.language ? 'text-blue' : 'text-gray-300'
                  }`}
                  data-test={`language-switcher-option-button-${dashboardLocale}`}
                >
                  <p onClick={() => handleChangeLanguage(dashboardLocale as LocaleEnum)}>
                    {localeNames[dashboardLocale as LocaleEnum]}
                  </p>
                </button>
              </li>
            ))}
          </ul>
        </Popover.Panel>
      </Float>
    </Popover>
  )
}
