import Link, { LinkProps } from 'next/link'
import React, { AnchorHTMLAttributes, forwardRef } from 'react'

export type LinkType = React.PropsWithChildren<LinkProps> & AnchorHTMLAttributes<HTMLAnchorElement>

const LinkWithoutPrefetch = forwardRef<HTMLAnchorElement, LinkType>((props, ref) => {
  return <Link {...props} prefetch={false} ref={ref} />
})
LinkWithoutPrefetch.displayName = 'LinkWithoutPrefetch'
export default LinkWithoutPrefetch
