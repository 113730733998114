export const invalidTokenErrorMessages = [
  'Invalid JWT Token',
  'JWT Token not found',
  'Expired JWT Token',
]

export class InvalidTokenError extends Error {
  constructor() {
    super()
    this.name = this.constructor.name
  }
}
