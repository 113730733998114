import { DateFormatEnum, TimeFormatEnum } from 'shared/enums/date-time-format-enum'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { UserInterface } from 'shared/types/user-interface'

export const dateTimeFormat = (
  date: string | Date,
  format: {
    dateFormat?: DateFormatEnum
    timeFormat?: TimeFormatEnum
    timeZone?: UserInterface['timezone']
  },
) => {
  const isShort =
    format.timeFormat === TimeFormatEnum.hourMinuteAmPm ||
    format.timeFormat === TimeFormatEnum.hourMinuteSecondAmPm
  const nonFormatDate = (typeof date === 'string' ? new Date(date) : date).toLocaleString(
    getFormat(format.dateFormat),
    {
      second:
        format.timeFormat === TimeFormatEnum.hourMinuteAmPm ||
        format.timeFormat === TimeFormatEnum.hourMinute
          ? undefined
          : '2-digit',
      minute: '2-digit',
      hour: '2-digit',
      day: '2-digit',
      month: '2-digit',
      hourCycle: isShort ? 'h12' : 'h23',
      year: 'numeric',
      timeZone: format.timeZone,
    },
  )
  return isShort ? nonFormatDate.replace('am', 'AM').replace('pm', 'PM') : nonFormatDate
}

export const timeFormat = (
  date: string | Date,
  format: {
    dateFormat?: DateFormatEnum
    timeFormat?: TimeFormatEnum
    timeZone?: UserInterface['timezone']
  },
) => {
  const isShort =
    format.timeFormat === TimeFormatEnum.hourMinuteAmPm ||
    format.timeFormat === TimeFormatEnum.hourMinuteSecondAmPm
  const nonFormatDate = (typeof date === 'string' ? new Date(date) : date).toLocaleString(
    getFormat(format.dateFormat),
    {
      second:
        format.timeFormat === TimeFormatEnum.hourMinuteAmPm ||
        format.timeFormat === TimeFormatEnum.hourMinute
          ? undefined
          : '2-digit',
      minute: '2-digit',
      hour: '2-digit',
      hourCycle: isShort ? 'h12' : 'h23',
      timeZone: format.timeZone,
    },
  )
  return isShort ? nonFormatDate.replace('am', 'AM').replace('pm', 'PM') : nonFormatDate
}

export const dateFormat = (
  date: string | Date,
  format: { dateFormat?: DateFormatEnum; timeZone?: UserInterface['timezone'] },
) =>
  (typeof date === 'string' ? new Date(date) : date).toLocaleString(getFormat(format.dateFormat), {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: format.timeZone,
  })

const getFormat = (dateFormat?: DateFormatEnum): string | undefined => {
  switch (dateFormat) {
    case DateFormatEnum.dateFirst:
      return 'en-GB'
    case DateFormatEnum.monthFirst:
      return 'en-US'

    default:
      return undefined
  }
}

export const getTimezoneDate = (date: Date, timeZone?: UserInterface['timezone']) =>
  new Date(
    new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(date),
  )

export const setDateWithTimezone = (date?: string | Date, timeZone?: UserInterface['timezone']) => {
  if (date) {
    if (date instanceof Date) {
      return getTimezoneDate(date, timeZone)
    }
    if (date.match(/([-+]\d+:?\d+)$/)) {
      return getTimezoneDate(new Date(date), timeZone)
    } else {
      return new Date(date)
    }
  } else {
    return getTimezoneDate(new Date(), timeZone)
  }
}

export const removeTimezoneFromString = (date: string) => {
  if (date.match(/([-+]\d+:?\d+)$/)) {
    return date.replace(/([-+]\d{2}:?\d{2})$/, '')
  } else {
    return date
  }
}

export const toISOStringWithoutTimezone = (date: Date) => {
  const pad = function (num: number) {
    return (num < 10 ? '0' : '') + num
  }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds())
  )
}

export const toShortDate = (date: string, locale?: LocaleEnum) =>
  new Date(Date.parse(date)).toLocaleString(locale, { month: 'short', day: '2-digit' })

export const toShortMonth = (date: string, locale?: LocaleEnum) =>
  new Date(Date.parse(date)).toLocaleString(locale, { month: 'short' })

export function isYesterday(date: string | null, timeZone?: UserInterface['timezone']) {
  const today: Date = setDateWithTimezone('', timeZone)
  const lastDate: Date = new Date(today)
  lastDate.setDate(lastDate.getDate() - 1)
  lastDate.setHours(0, 0, 0, 0)
  const selectedDate = setDateWithTimezone(date || '', timeZone)

  return lastDate.toDateString() === selectedDate.toDateString()
}

export const filterPassedTime = (time: Date, timeZone?: UserInterface['timezone']) => {
  const currentDate = setDateWithTimezone('', timeZone || 'Europe/London')
  return currentDate.getTime() < time.getTime()
}
