import React, { PropsWithChildren, createContext, useContext, useRef } from 'react'
import { Key } from 'swr'

export const useSwrKeyWithVersion = (key: Key) => {
  const { getVersion, updateVersion } = useSWRCount()

  const strKey = typeof key === 'function' ? key() : key

  const normalizedKey = strKey ? strKey : null

  const version = getVersion(normalizedKey)

  return {
    updateVersion: () => updateVersion(normalizedKey),
    keyWithVersion: [normalizedKey, version],
  }
}

type SWRCountContextType = {
  getVersion: (key: string) => number
  updateVersion: (key: string) => void
}
const SWRCountContext = createContext<SWRCountContextType | null>(null)

const useSWRCount = () => {
  const context = useContext(SWRCountContext)
  if (!context) {
    throw new Error('useSWRCount must be used within a SWRCountProvider')
  }
  return context
}

function SWRCountProvider({ children }: PropsWithChildren) {
  const swrCount = useRef<Record<string, number>>({})
  const getVersion = (key: string) => {
    const initVersion = 1
    if (swrCount.current[key]) {
      return swrCount.current[key]
    } else {
      swrCount.current = { ...swrCount.current, [key]: initVersion }
      return initVersion
    }
  }

  const updateVersion = (key: string) => {
    swrCount.current = { ...swrCount.current, [key]: swrCount.current[key] + 1 }
  }

  return (
    <SWRCountContext.Provider value={{ getVersion, updateVersion }}>
      {children}
    </SWRCountContext.Provider>
  )
}

export default SWRCountProvider
