import React from 'react'

function MentionIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9998 2.25C10.6876 2.25155 9.38914 2.51772 8.18215 3.03258C6.97516 3.54744 5.88439 4.30042 4.97507 5.24649C4.06576 6.19257 3.35657 7.3123 2.88992 8.53874C2.42327 9.76518 2.20874 11.0731 2.25916 12.3844C2.45604 17.5125 6.66541 21.6469 11.8029 21.75C13.5245 21.7835 15.2243 21.3605 16.7294 20.524C18.2345 19.6875 19.4912 18.4673 20.3717 16.9875C20.4085 16.926 20.427 16.8552 20.4248 16.7836C20.4227 16.7119 20.4001 16.6423 20.3596 16.5831C20.3192 16.5239 20.2626 16.4776 20.1966 16.4496C20.1306 16.4215 20.058 16.413 19.9873 16.425C19.7025 16.4753 19.4139 16.5004 19.1248 16.5C17.4092 16.5 16.4529 15.75 15.9467 15.1219C15.8298 14.9731 15.7233 14.8165 15.6279 14.6531C15.174 15.2749 14.5683 15.7698 13.8685 16.0906C13.1687 16.4114 12.3983 16.5474 11.631 16.4854C10.8637 16.4234 10.1252 16.1657 9.48592 15.7368C8.84668 15.3078 8.32817 14.7221 7.97995 14.0356C7.63173 13.349 7.46545 12.5846 7.49701 11.8155C7.52858 11.0463 7.75693 10.2981 8.16023 9.64243C8.56353 8.98673 9.1283 8.44545 9.80053 8.07035C10.4728 7.69526 11.23 7.49888 11.9998 7.5C13.1086 7.49662 14.1787 7.90792 14.9998 8.65313V8.27813C14.9967 8.08351 15.0676 7.89496 15.1981 7.75055C15.3286 7.60613 15.509 7.51659 15.7029 7.5C15.8053 7.49359 15.9079 7.50824 16.0043 7.54305C16.1008 7.57786 16.1891 7.63209 16.2638 7.70238C16.3385 7.77268 16.398 7.85754 16.4386 7.95173C16.4791 8.04592 16.5 8.14744 16.4998 8.25V12C16.4998 13.3687 16.9592 15 19.1248 15C21.2904 15 21.7123 13.4813 21.7498 12.1406V12C21.7473 9.4149 20.7193 6.93639 18.8913 5.10845C17.0634 3.28051 14.5849 2.25248 11.9998 2.25Z"
        fill="currentColor"
      />
      <path
        d="M11.9998 15C13.6566 15 14.9998 13.6569 14.9998 12C14.9998 10.3431 13.6566 9 11.9998 9C10.3429 9 8.99979 10.3431 8.99979 12C8.99979 13.6569 10.3429 15 11.9998 15Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default MentionIcon
