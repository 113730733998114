import { useEffect, useMemo, useState } from 'react'
import { AdminRolesEnum, UserRolesEnum, UserStatusEnum } from 'shared/enums/user-roles-enum'
import { UserWorkspaceRolesEnum } from 'shared/enums/user-workspace-roles-enum'
import { UserInterface } from 'shared/types/user-interface'

export const useUserStatus = (dashboardUser?: UserInterface) => {
  const isCustomer = dashboardUser?.roles.includes(UserRolesEnum.customer) || false

  const isAffiliate = dashboardUser?.roles.includes(UserRolesEnum.affiliate) || false

  const isStudent = dashboardUser?.roles.includes(UserRolesEnum.student) || false

  const isWorkspaceOwner = dashboardUser ? dashboardUser.workspaceOwner === null : false

  const isRootAdmin = dashboardUser?.id === 161 || dashboardUser?.workspaceOwner === 161

  const checkAdminRoles = (adminRoles?: AdminRolesEnum[]) =>
    (adminRoles || Object.values(AdminRolesEnum)).filter(el => dashboardUser?.roles.includes(el))
      .length !== 0

  const checkImpersonatorAdminRoles = (adminRoles?: AdminRolesEnum[]) =>
    (adminRoles || Object.values(AdminRolesEnum)).filter(el =>
      dashboardUser?.impersonator?.roles.includes(el),
    ).length !== 0

  const isWsAdmin = useMemo(() => {
    if (!isWorkspaceOwner && dashboardUser) {
      const workspace = dashboardUser.workspaceMemberships?.find(
        el => el.ownerId === dashboardUser.workspaceOwner,
      )
      return workspace ? workspace.role === UserWorkspaceRolesEnum.workspaceAdmin : false
    } else {
      return false
    }
  }, [dashboardUser, isWorkspaceOwner])

  const isWsAssistant = useMemo(() => {
    if (!isWorkspaceOwner && dashboardUser) {
      const workspace = dashboardUser.workspaceMemberships?.find(
        el => el.ownerId === dashboardUser.workspaceOwner,
      )
      return workspace ? workspace.role === UserWorkspaceRolesEnum.workspaceAssistant : false
    } else {
      return false
    }
  }, [dashboardUser, isWorkspaceOwner])

  const [userStatus, setUserStatus] = useState<UserStatusEnum>(UserStatusEnum.customer)

  useEffect(() => {
    if (!isStudent && isAffiliate && !isCustomer) {
      setUserStatus(UserStatusEnum.affiliate)
    } else if (isStudent && isAffiliate && !isCustomer) {
      setUserStatus(UserStatusEnum.affiliate_student)
    } else if (isCustomer) {
      setUserStatus(UserStatusEnum.customer)
    }
  }, [isCustomer, isAffiliate, isStudent])

  return {
    userStatus,
    isCustomer,
    isAffiliate,
    isStudent,
    isWsAdmin,
    isWsAssistant,
    isWorkspaceOwner,
    isRootAdmin,
    checkAdminRoles,
    checkImpersonatorAdminRoles,
  }
}
