import * as React from 'react'
import useWindowSize from 'shared/hooks/use-window-size'

const findTextNode = (node: Node): Node => {
  return node.hasChildNodes()
    ? node.childNodes[0]?.nodeType === 3
      ? node
      : findTextNode((node as Node).childNodes[0])
    : node
}

export const useIsOverflowText = (
  ref: React.RefObject<any>,
  callback?: (hasOverflow: boolean) => void,
) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean | undefined>(undefined)
  const { width } = useWindowSize()

  React.useLayoutEffect(() => {
    const { current } = ref

    const trigger = () => {
      const textNode = findTextNode(current) as Element
      if (!textNode) return
      const hasOverflow = textNode.scrollWidth > current.clientWidth

      setIsOverflow(hasOverflow)

      if (callback) callback(hasOverflow)
    }

    if (current) {
      trigger()
    }
  }, [callback, ref, width])

  return isOverflow
}
