export enum DateFormatEnum {
  dateFirst = 'd/m/Y',
  monthFirst = 'm/d/Y',
}

export enum TimeFormatEnum {
  hourMinute = 'H:i',
  hourMinuteAmPm = 'h:i A',
  hourMinuteSecond = 'H:i:s',
  hourMinuteSecondAmPm = 'h:i:s A',
}
