import { Dispatch, SetStateAction } from 'react'
import { QueryType } from 'shared/route-query/types/route-query-interface'
import { LimitType, PaginationInterface } from 'shared/types/pagination-interface'

export const setPaginationQuery = <T>({
  limit,
  startFromId,
  order,
  setPagination,
  setLimit,
}: QueryType<T> & {
  setPagination: Dispatch<SetStateAction<PaginationInterface>>
  setLimit: Dispatch<SetStateAction<LimitType>>
}) => {
  if (order) {
    setPagination(prev => ({ ...prev, order }))
  }

  if (startFromId) {
    setPagination(prev => ({ ...prev, startFromId }))
  }

  if (limit) {
    setLimit(limit)
  }
}
