import React from 'react'

function ManInSuit(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 26"
      fill="#ACB8D1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.93798 8.12999C6.02798 8.54399 6.16598 8.81199 6.32698 8.97899C6.70998 11.645 9.10298 13.917 11.025 13.822C13.47 13.702 15.203 11.067 15.592 8.97899C15.753 8.81299 15.908 8.45799 16.001 8.04099C16.105 7.56199 16.217 6.83999 15.929 6.45799C15.912 6.43799 15.802 6.33699 15.783 6.31999C16.058 5.32799 16.662 3.55799 15.158 1.96699C14.343 1.10499 13.211 0.671994 12.188 0.329994C9.16798 -0.679006 7.03598 0.735994 6.05198 3.08899C5.98098 3.25599 5.52198 4.31299 6.07798 6.31999C6.02398 6.35599 5.97498 6.40199 5.93398 6.45799C5.64498 6.83899 5.83298 7.65099 5.93798 8.12999Z" />
      <path d="M21.557 22.792C21.473 20.957 21.369 18.049 19.766 15.67C19.766 15.67 19.309 15.047 18.225 14.633C18.225 14.633 15.871 13.916 14.787 13.141L14.292 13.48L14.347 16.698L11.375 24.632C11.31 24.806 11.144 24.921 10.959 24.921C10.774 24.921 10.608 24.806 10.543 24.632L7.57197 16.698C7.57197 16.698 7.62697 13.49 7.62597 13.48C7.63297 13.507 7.12997 13.141 7.12997 13.141C6.04797 13.916 3.69297 14.633 3.69297 14.633C2.60897 15.047 2.15197 15.67 2.15197 15.67C0.549971 18.049 0.443971 20.957 0.359971 22.792C0.301971 24.06 0.567971 24.533 0.901971 24.668C5.04797 26.332 16.867 26.332 21.014 24.668C21.35 24.534 21.614 24.06 21.557 22.792Z" />
      <path d="M11.0649 14.847L10.9309 14.85C10.4989 14.85 10.0629 14.766 9.63495 14.618L10.8129 16.421L9.75595 17.441L10.8439 24.048C10.8529 24.105 10.9019 24.146 10.9599 24.146C11.0169 24.146 11.0659 24.105 11.0759 24.048L12.1639 17.441L11.1059 16.421L12.2669 14.645C11.8879 14.756 11.4869 14.83 11.0649 14.847Z" />
    </svg>
  )
}

export default ManInSuit
