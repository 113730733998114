import { Configuration } from 'rollbar'
import { fullMatchIgnoredClasses, partialMatchIgnoredMessages } from 'rollbar/constants'

export const checkIgnore: Configuration['checkIgnore'] = (_, __, item) => {
  const payload = item as {
    body: {
      trace: { exception: { message: string; class: string }; frames: { filename?: string }[] }
    }
  }
  const message = payload.body.trace.exception.message
  const errorClass = payload.body.trace.exception.class
  const frames = payload.body.trace.frames

  const hasSomePartialMatchMessage = partialMatchIgnoredMessages.some(partialMatchIgnoreMessage =>
    checkIsPartialMatchStringMatched(message, partialMatchIgnoreMessage),
  )
  const hasSomeFullMatchClass = fullMatchIgnoredClasses.includes(errorClass)

  const hasSomeExternalFrame = frames.some(checkExternalFrame)

  return hasSomePartialMatchMessage || hasSomeFullMatchClass || hasSomeExternalFrame
}

const checkIsPartialMatchStringMatched = (strFromProps: string, partialMatchStr: string[]) => {
  let str = strFromProps
  for (const partOfMessage of partialMatchStr) {
    if (str.includes(partOfMessage)) {
      str = removeMathchedPartialStr(str, partOfMessage)
    } else {
      return false
    }
  }
  return true
}

const removeMathchedPartialStr = (str: string, matchedPartialStr: string) => {
  const startIndex = str.indexOf(matchedPartialStr)
  const removePositionUntil = startIndex + matchedPartialStr.length
  return str.slice(removePositionUntil)
}

const checkExternalFrame = (frame: { filename?: string }) => {
  if (frame.filename) {
    return frame.filename.startsWith('http')
  }
  return false
}
