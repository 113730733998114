import { useRouter } from 'next/router'
import {
  MenuItemInterface,
  SingleItemDropdownInterface,
} from 'shared/components/header/types/menu-interface'
import { isFlatMenuItem } from 'shared/components/header/utils/is-flat-menu-item'

const comparePaths = ({ urlPath, menuPath }: { urlPath: string; menuPath: string }) => {
  const replaceBasePath = (path: string) => path.replace(/\/d/g, '')
  const replacedUrlPath = replaceBasePath(urlPath)
  const replacedMenuPath = replaceBasePath(menuPath)

  const isMainPage = replacedMenuPath === '/'
  const isSettingsPage =
    replacedMenuPath.includes('/profile') && replacedMenuPath !== '/profile/change-password'

  if (isMainPage) {
    return replacedUrlPath === replacedMenuPath
  }

  if (isSettingsPage) {
    return replacedUrlPath.includes('/profile') && replacedUrlPath !== '/profile/change-password'
  }

  return replacedUrlPath.includes(replacedMenuPath)
}

export const useIsActive = (item: MenuItemInterface['item']) => {
  const { pathname } = useRouter()
  if (isFlatMenuItem(item)) return comparePaths({ urlPath: pathname, menuPath: item.href })
  else {
    const isActive = (items: SingleItemDropdownInterface['dropdownItems']): boolean =>
      !!items?.find(el =>
        isFlatMenuItem(el)
          ? comparePaths({ urlPath: pathname, menuPath: el.href })
          : isActive(el.dropdownItems),
      )
    return isActive(item.dropdownItems)
  }
}
