import React from 'react'

function LikeIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg height="16" width="16" viewBox="0 0 74 74" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m17.56 30.32h-10.58a3.649 3.649 0 0 0 -3.64 3.65v34.38a3.649 3.649 0 0 0 3.64 3.65h10.58a3.658 3.658 0 0 0 3.65-3.65v-34.38a3.658 3.658 0 0 0 -3.65-3.65z" />
      <path d="m69.09 42.29a6.231 6.231 0 0 0 -1.82-4.4 3 3 0 0 0 -.32-.3 6.081 6.081 0 0 0 -2.36-11.68h-21.7c.12-.21.23-.4.33-.59a14.353 14.353 0 0 0 1.48-3.22 20.85 20.85 0 0 0 .87-4.82 17.74 17.74 0 0 0 -4.6-12.99 6.772 6.772 0 0 0 -4.77-2.29 4.923 4.923 0 0 0 -5.08 4.36.66.66 0 0 0 -.01.14v8.76l-5.8 14.7a9.425 9.425 0 0 1 -2.1 3.19v33.88a33.044 33.044 0 0 0 4.36.89 12.466 12.466 0 0 0 1.65.11h29.41a5.522 5.522 0 0 0 3.88-1.6 5.464 5.464 0 0 0 1.6-3.87 5.407 5.407 0 0 0 -1.36-3.6 6.283 6.283 0 0 0 2.7-11 6.245 6.245 0 0 0 3.64-5.67z" />
    </svg>
  )
}

export default LikeIcon
