import { GeneralFieldsType } from 'shared/types/error-response-content-interface'

export const checkAreThereFieldsErrors = (fields: GeneralFieldsType): boolean => {
  if (typeof fields !== 'object' || Array.isArray(fields) || fields === null) {
    console.error('field has wrong type')
    return false
  }
  return Object.values(fields).some(field =>
    Array.isArray(field) ? field.length : checkAreThereFieldsErrors(field),
  )
}
