import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useCallback, useState } from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BellIcon from 'shared/icons/bell-icon'
import DoubleCheckmarkIcon from 'shared/icons/double-checkmark-icon'
import SettingsIcon from 'shared/icons/settings-icon'
import ThinArrowRightIcon from 'shared/icons/thin-arrow-right-icon'
import { useFilterPagination } from 'shared/route-query/hooks/use-filter-pagination'
import { twMerge } from 'tailwind-merge'
import { useNotificationsActions } from '../hooks/use-notification-actions'
import { useNotifications } from '../hooks/use-notifications'
import NotificationEmptyList from './notification-empty-list'
import NotificationItem from './notification-item'
import NotificationsListSkeleton from './notifications-list-skeleton'
import UnreadIndicator from './unread-indicator'

export interface NotificationMenuProps {
  className?: string
}

const defaultFilter = {
  onlyUnRead: false,
}

const NotificationMenuMobile = ({ className }: NotificationMenuProps) => {
  const { t } = useLocoTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [shouldFetch, setShouldFetch] = useState(false)

  const toggleMenuState = useCallback(() => {
    setIsMenuOpen(prevState => !prevState)
  }, [])

  const { pagination } = useFilterPagination(
    {
      defaultFilter,
      initLimit: 3,
    },
    true,
  )
  const { notificationsMeta, notifications } = useNotifications({
    shouldFetch,
    limit: 3,
    pagination,
    filter: defaultFilter,
  })
  const { markAllNotificationsAsRead } = useNotificationsActions()

  return (
    <>
      <button
        onClick={toggleMenuState}
        onMouseEnter={() => {
          setShouldFetch(true)
        }}
        className="h-full flex items-center justify-around gap-2.5 focus:outline-none border-b-4 border-transparent focus-visible:border-blue"
      >
        <div className="relative w-5 text-sm font-bold text-white/90">
          <BellIcon
            className={twMerge(
              'transition fill-gray stroke-gray hover:fill-white hover:stroke-white',
              isMenuOpen && 'fill-white stroke-white',
            )}
          />
          {notificationsMeta?.hasUnread && (
            <UnreadIndicator className="absolute top-0 right-0 p-0" />
          )}
        </div>
      </button>
      <Transition.Root show={isMenuOpen} as={Fragment}>
        <Dialog open={isMenuOpen} onClose={toggleMenuState}>
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="pt-[69px] flex w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={twMerge(
                    'relative w-full transform bg-white text-left shadow-xl transition-all',
                    className,
                  )}
                >
                  <div className="w-full flex flex-col text-sm bg-white py-4 shadow-lg">
                    <div className="flex justify-between flex-row px-6">
                      <h4 className="text-[18px] text-darkblue">
                        {t('dashboard.notifications.title')}
                      </h4>
                      <div className="inline-flex transition items-baseline flex-row gap-1 stroke-darkblue hover:stroke-blue text-darkblue hover:text-blue">
                        <DoubleCheckmarkIcon />
                        <span className="cursor-pointer" onClick={markAllNotificationsAsRead}>
                          {t('dashboard.notifications.mark_all_read')}
                        </span>
                      </div>
                    </div>
                    {notifications === undefined ? (
                      <NotificationsListSkeleton />
                    ) : notifications.items.length > 0 ? (
                      notifications.items.map(notification => (
                        <NotificationItem key={notification.id} notification={notification} />
                      ))
                    ) : (
                      <NotificationEmptyList />
                    )}
                    <div className="flex justify-between px-6 pt-4">
                      <LinkWithoutPrefetch
                        className="inline-flex group flex-row gap-1 items-center primary-link text-darkblue hover:text-blue"
                        href="/profile/notification-settings"
                      >
                        <SettingsIcon
                          className="fill-darkblue group-hover:fill-blue"
                          width={18}
                          height={18}
                        />
                        <h4>{t('dashboard.notifications.manage_notifications.title')}</h4>
                      </LinkWithoutPrefetch>
                      <LinkWithoutPrefetch
                        className="inline-flex items-center flex-row gap-3 primary-link stroke-darkblue hover:stroke-blue text-darkblue hover:text-blue"
                        href={`/notifications`}
                      >
                        <h4 className="capitalize">{t('dashboard.notifications.view_all')}</h4>
                        <ThinArrowRightIcon />
                      </LinkWithoutPrefetch>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default NotificationMenuMobile
