import React, { useEffect } from 'react'
import TransLoco from 'shared/components/trans-loco'
import { useCustomToasts } from 'shared/hooks/use-custom-toasts'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { UserSettingsLink } from 'shared/notifications/components/user-settings-link'
import { useSwrPaymentNotifications } from 'shared/notifications/hooks/use-swr-payment-notifications'

export const usePaymentNotification = () => {
  const { user, isCustomer, isWorkspaceOwner } = useUser()
  const { data: paymentNotifications } = useSwrPaymentNotifications()
  const { infoToast: paymentToast } = useCustomToasts()
  const { infoToast: profileToast } = useCustomToasts()
  const { t } = useLocoTranslation()

  useEffect(() => {
    if (!isCustomer || !user || !isWorkspaceOwner) return
    if (!paymentNotifications) return
    if (paymentNotifications.paymentNotification) {
      paymentToast(
        <span dangerouslySetInnerHTML={{ __html: paymentNotifications.paymentNotification }} />,
        {
          className: 'min-w-[600px]',
        },
      )
    }
    if (paymentNotifications.shouldFillProfile) {
      profileToast(
        <TransLoco
          t={t}
          i18nKey={'dashboard.notifications.fill_profile'}
          components={{
            a: <UserSettingsLink />,
          }}
        />,
      )
    }
    // TFunc should not be in dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomer, isWorkspaceOwner, paymentNotifications, paymentToast, profileToast, user])
}
