import React, { useEffect } from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { Tooltip } from 'shared/components/tooltip'
import TransLoco from 'shared/components/trans-loco'
import { useCustomToasts } from 'shared/hooks/use-custom-toasts'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { getAccountRestrictedLink } from 'shared/utils/get-account-restricted-link'

export const useAccountRestrictedNotification = () => {
  const { user, isWorkspaceOwner } = useUser()
  const { infoToast } = useCustomToasts()
  const { t } = useLocoTranslation()

  useEffect(() => {
    if (user && user.accessRestricted && isWorkspaceOwner) {
      infoToast(
        <span className={'flex flex-wrap text-yellow'}>
          <TransLoco
            t={t}
            i18nKey={'dashboard.notifications.account_restricted'}
            components={{
              a: <LinkWithoutPrefetch href={`/profile/user-plan`} className={'primary-link'} />,
            }}
          />
          <Tooltip wrapperClassName={'ml-1'} label={t('global.read_more')} mode={'hover'}>
            <button
              className={'group outline-none'}
              onClick={() => window.open(getAccountRestrictedLink(user.dashboardLocale))}
            >
              <QuestionMarkIcon className="group-focus-visible:fill-blue fill-gray-100 hover:fill-blue w-[16px] h-[16px]" />
            </button>
          </Tooltip>
        </span>,
        { className: 'min-w-[600px]' },
      )
    }
    // TFunc should not be in dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoToast, isWorkspaceOwner, user])
}
