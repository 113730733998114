import { AdminRolesEnum } from 'shared/enums/user-roles-enum'
import useUser from './use-user'

export const useShowChangePasswordPage = () => {
  const { dashboardUser, checkImpersonatorAdminRoles, isWorkspaceOwner } = useUser()

  return {
    showChangePasswordPage: dashboardUser?.impersonator
      ? checkImpersonatorAdminRoles([AdminRolesEnum.admin, AdminRolesEnum.superAdmin])
      : isWorkspaceOwner,
  }
}
