import {
  ErrorResponseContentInterface,
  ErrorResponseInterface,
} from 'shared/types/error-response-content-interface'

export class ConflictError<F> extends Error {
  public errors: ErrorResponseContentInterface<F>

  constructor({ errors }: ErrorResponseInterface<F>) {
    super()
    this.errors = errors
  }
}
