import { useRollbar } from '@rollbar/react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { regenerateJWT } from 'shared/api/regenerate-jwt'
import { checkAreThereFieldsErrors } from 'shared/api/utils/check-field-errors'
import { AbortError } from 'shared/errors/abort-error'
import { AccessDenied } from 'shared/errors/access-denied'
import { BadRequest } from 'shared/errors/bad-request'
import { ConflictError } from 'shared/errors/conflict-error'
import { InternalError } from 'shared/errors/internal-error'
import { InvalidTokenError } from 'shared/errors/invalid-token-error'
import { NotFound } from 'shared/errors/not-found'
import { PayloadTooLarge } from 'shared/errors/payload-too-large'
import { UnauthorizedResponse } from 'shared/errors/unauthorized-response'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import {
  ErrorResponseInterface,
  GeneralFieldsType,
} from 'shared/types/error-response-content-interface'
import httpClient from 'shared/utils/http-client'
import { FetcherType, RequestMethodsEnum, UseApiWrapperWithErrorValidationProps } from './types'

let isJWTRegenerated = false

export const useApiWrapperWithErrorValidation = <
  M extends RequestMethodsEnum,
  R = void,
  D = any,
  BadRequestError = ErrorResponseInterface,
>({
  method,
  showFieldsErrorToast,
  badRequestHandler,
  unauthorizedResponseHandler,
  unprocessableContentHandler,
  internalErrorHandler,
  afterFetch,
  stopErrorPropagation,
}: UseApiWrapperWithErrorValidationProps<R, M, BadRequestError>) => {
  const { t } = useLocoTranslation()

  const router = useRouter()
  const rollbar = useRollbar()

  const [numberOfRequests, setNumberOfRequests] = useState<number>(0)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => setIsFetching(!!numberOfRequests), [numberOfRequests])

  const fetcher: FetcherType<M, R> = useCallback(
    async (...args) => {
      setNumberOfRequests(numberOfRequests => numberOfRequests + 1)
      return httpClient[method]<R, AxiosResponse<R>, D>(
        args[0],
        args[1] as (D & AxiosRequestConfig<D>) | undefined,
        args[2] as AxiosRequestConfig<D>,
      )
        .then(async res => res && (afterFetch ? await afterFetch(res.data) : res.data))
        .catch(async error => {
          if (typeof window !== 'undefined' && error instanceof NotFound) {
            if (process.env.NODE_ENV !== 'development') {
              router.replace('/404')
            }
          } else if (typeof window !== 'undefined' && error instanceof AccessDenied) {
            router.push('/403')
          } else if (typeof window !== 'undefined' && error instanceof UnauthorizedResponse) {
            if (unauthorizedResponseHandler) {
              unauthorizedResponseHandler(error)
            } else {
              window.location.assign(error.location)
            }
          } else if (error instanceof InvalidTokenError) {
            if (isJWTRegenerated) {
              toast.error(t('global.error'))
              rollbar.error('Invalid token error', error)
            } else {
              try {
                // NOTE: Generating a new JWT
                await regenerateJWT()
                isJWTRegenerated = true
                // NOTE: repeat the same request
                return fetcher(...args)
              } catch (error) {
                if (error instanceof UnauthorizedResponse) {
                  window.location.assign(error.location)
                }
              }
            }
          } else if (typeof window !== 'undefined' && error instanceof InternalError) {
            if (internalErrorHandler) {
              internalErrorHandler(error)
            } else {
              toast.error(t('core.error.internal_error_message'))
            }
          } else if (error instanceof BadRequest) {
            if (
              showFieldsErrorToast &&
              (error as ErrorResponseInterface<GeneralFieldsType>)?.errors &&
              checkAreThereFieldsErrors(
                (error as ErrorResponseInterface<GeneralFieldsType>)?.errors?.fields,
              )
            ) {
              toast.error(t('global.errors.fields_toast_message'))
            }

            if (badRequestHandler !== null && badRequestHandler) {
              badRequestHandler(error as unknown as BadRequestError)
            } else {
              const commonError = (error as ErrorResponseInterface)?.errors?.common
              if (commonError && commonError.length) {
                toast.error(<p dangerouslySetInnerHTML={{ __html: commonError.join('') }} />)
              }
            }
          } else if (error instanceof ConflictError) {
            toast.error(t('core.error.conflict_error_message'))
          } else if (error instanceof UnprocessableContentError) {
            if (unprocessableContentHandler) {
              unprocessableContentHandler(error)
            } else {
              toast.error(t('core.error.conflict_error_message'))
            }
          } else if (error instanceof PayloadTooLarge) {
            toast.error(t('core.error.payload_to_large_error_message'))
          } else if (error instanceof AbortError) {
            throw error
          } else {
            !axios.isCancel(error) && toast.error(t('global.error'))
          }
          if (!stopErrorPropagation) {
            throw error
          }
        })
        .finally(() => setNumberOfRequests(numberOfRequests => numberOfRequests - 1))
    },
    // Tfunc causes re rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      method,
      afterFetch,
      router,
      unauthorizedResponseHandler,
      showFieldsErrorToast,
      badRequestHandler,
      unprocessableContentHandler,
    ],
  ) as FetcherType<M, R>
  return { fetcher, isFetching }
}
