import React from 'react'
import useUser from 'shared/hooks/use-user'
import HelpScoutScript from './components/help-scout-script'

function LiveChat() {
  const { user } = useUser()

  if (!user) {
    return null
  }

  return <HelpScoutScript />
}

export default LiveChat
