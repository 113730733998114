import { AttachmentFileInterface } from 'shared/components/dashboard-mail-editor/types/email-interface'

export function isAttachmentFile(
  file: AttachmentFileInterface | File,
): file is AttachmentFileInterface {
  return (file as AttachmentFileInterface).id !== undefined
}

export function getFilteredAttachmentsList(
  state: (AttachmentFileInterface | File)[],
  file: AttachmentFileInterface | File,
) {
  if (isAttachmentFile(file)) {
    return state.filter(attachment => {
      if (isAttachmentFile(attachment)) return attachment.id !== file.id
      return attachment
    })
  } else {
    return state.filter(attachment => {
      if (isAttachmentFile(attachment)) {
        return attachment
      } else {
        return attachment.name !== file.name && attachment.size !== file.size
      }
    })
  }
}
