export interface GetFilterQueryString<T> {
  filter: T
}

export const getFilterQueryString = <
  T extends Record<string, string | number | boolean | null | undefined | number[]>,
>({
  filter,
}: GetFilterQueryString<T>) =>
  filter
    ? Object.entries(filter).reduce((res, [key, value]) => {
        if (Array.isArray(value)) {
          return `${res}${value.reduce(
            (acc, val) => `${acc}&filter[${key}][]=${encodeURIComponent(val)}`,
            '',
          )}`
        } else {
          return `${res}${value ? `&filter[${key}]=${encodeURIComponent(value)}` : ''}`
        }
      }, '')
    : ''
