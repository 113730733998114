import { SwrPaginationInterface } from 'shared/types/pagination-interface'

export interface GetPaginationQueryStringProps extends SwrPaginationInterface {}

export const getPaginationQueryString = ({ limit, pagination }: GetPaginationQueryStringProps) => {
  const queries = []

  queries.push(`pagination[order]=${pagination.order}`)

  if (pagination.startFromId !== undefined) {
    queries.push(`pagination[startFrom]=${pagination.startFromId}`)
  }

  if (limit !== undefined) {
    queries.push(`pagination[limit]=${limit}`)
  }

  return queries.join('&')
}
