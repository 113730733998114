import React from 'react'

function InfoIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="#F04438"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0.759766C3.13428 0.759766 0 3.89518 0 7.75977C0 11.6266 3.13428 14.7598 7 14.7598C10.8657 14.7598 14 11.6266 14 7.75977C14 3.89518 10.8657 0.759766 7 0.759766ZM7 3.86461C7.65473 3.86461 8.18548 4.39537 8.18548 5.05009C8.18548 5.70482 7.65472 6.23557 7 6.23557C6.34527 6.23557 5.81452 5.70481 5.81452 5.05009C5.81452 4.39536 6.34528 3.86461 7 3.86461ZM8.58065 11.034C8.58065 11.221 8.42899 11.3727 8.24194 11.3727H5.75807C5.57102 11.3727 5.41936 11.221 5.41936 11.034V10.3565C5.41936 10.1695 5.57102 10.0178 5.75807 10.0178H6.09678V8.21139H5.75807C5.57102 8.21139 5.41936 8.05973 5.41936 7.87268V7.19526C5.41936 7.00821 5.57102 6.85655 5.75807 6.85655H7.56452C7.75157 6.85655 7.90323 7.00821 7.90323 7.19526V10.0178H8.24194C8.42899 10.0178 8.58065 10.1695 8.58065 10.3565V11.034Z"
      />
    </svg>
  )
}

export default InfoIcon
