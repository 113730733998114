import React from 'react'

function QuestionMarkIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#00A0FF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8813 9.18766C10.687 9.54872 10.3116 9.77547 9.90164 9.77949C9.49164 9.7835 9.11193 9.56414 8.91059 9.20696C8.70926 8.84978 8.71819 8.41134 8.93391 8.06266C9.69772 6.73986 11.2548 6.09497 12.7302 6.49031C14.2056 6.88566 15.2316 8.22267 15.2317 9.75016C15.2317 10.9854 14.4745 11.9934 13.5442 12.5627C12.7972 13.0194 13.2629 14.2502 11.8533 14.2502C11.5347 14.25 11.2311 14.1147 11.0179 13.8779C10.8047 13.6411 10.7019 13.325 10.735 13.0082C10.7654 12.413 11.0309 11.9282 11.32 11.5772C11.6249 11.2059 12.0265 10.9022 12.3325 10.7199C14.0392 9.70516 11.9579 7.62953 10.8813 9.18878V9.18766ZM11.8533 15.3752C11.232 15.3752 10.7283 15.8788 10.7283 16.5002C10.7283 17.1215 11.232 17.6252 11.8533 17.6252C12.4746 17.6252 12.9783 17.1215 12.9783 16.5002C12.9783 15.8788 12.4746 15.3752 11.8533 15.3752Z"
        fill="white"
      />
    </svg>
  )
}

export default QuestionMarkIcon
