export enum LocaleEnum {
  ENGLISH = 'en',
  FRENCH = 'fr',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  DEUTSCH = 'de',
  DUTCH = 'nl',
  RUSSIAN = 'ru',
  JAPANESE = 'jp',
  ARABIC = 'ar',
  TURKISH = 'tr',
  CHINESE = 'zh',
  SWEDISH = 'sv',
  ROMANIAN = 'ro',
  CZECH = 'cs',
  HUNGARIAN = 'hu',
  SLOVAK = 'sk',
  DANISH = 'dk',
  INDONESIAN = 'id',
  POLISH = 'pl',
  GREEK = 'el',
  SERBIAN = 'sr',
  NORWEGIAN = 'no',
  THAI = 'th',
  SLOVENIAN = 'sl',
  UKRAINIAN = 'ua',
  ALBANIA = 'sq',
  HINDI = 'hi',
}

export const localeLabels: Record<LocaleEnum, string> = {
  [LocaleEnum.ENGLISH]: 'EN',
  [LocaleEnum.FRENCH]: 'FR',
  [LocaleEnum.SPANISH]: 'ES',
  [LocaleEnum.ITALIAN]: 'IT',
  [LocaleEnum.PORTUGUESE]: 'PT',
  [LocaleEnum.DEUTSCH]: 'DE',
  [LocaleEnum.DUTCH]: 'NL',
  [LocaleEnum.RUSSIAN]: 'RU',
  [LocaleEnum.JAPANESE]: 'JP',
  [LocaleEnum.ARABIC]: 'AR',
  [LocaleEnum.TURKISH]: 'TR',
  [LocaleEnum.CHINESE]: 'CH',
  [LocaleEnum.SWEDISH]: 'SV',
  [LocaleEnum.ROMANIAN]: 'RO',
  [LocaleEnum.CZECH]: 'CS',
  [LocaleEnum.HUNGARIAN]: 'HU',
  [LocaleEnum.SLOVAK]: 'SK',
  [LocaleEnum.DANISH]: 'DK',
  [LocaleEnum.INDONESIAN]: 'ID',
  [LocaleEnum.POLISH]: 'PL',
  [LocaleEnum.GREEK]: 'EL',
  [LocaleEnum.SERBIAN]: 'SR',
  [LocaleEnum.HINDI]: 'HI',
  [LocaleEnum.NORWEGIAN]: 'NO',
  [LocaleEnum.THAI]: 'TH',
  [LocaleEnum.SLOVENIAN]: 'SL',
  [LocaleEnum.UKRAINIAN]: 'UA',
  [LocaleEnum.ALBANIA]: 'SQ',
}

export const localeNames: Record<LocaleEnum, string> = {
  [LocaleEnum.ENGLISH]: 'English',
  [LocaleEnum.FRENCH]: 'Français',
  [LocaleEnum.SPANISH]: 'Español',
  [LocaleEnum.ITALIAN]: 'Italiano',
  [LocaleEnum.PORTUGUESE]: 'Português',
  [LocaleEnum.DEUTSCH]: 'Deutsch',
  [LocaleEnum.DUTCH]: 'Nederlands',
  [LocaleEnum.RUSSIAN]: 'Русский',
  [LocaleEnum.JAPANESE]: '日本語',
  [LocaleEnum.ARABIC]: 'عربي',
  [LocaleEnum.TURKISH]: 'Türkçe',
  [LocaleEnum.CHINESE]: '中文(简体)',
  [LocaleEnum.SWEDISH]: 'Svenska',
  [LocaleEnum.ROMANIAN]: 'Română',
  [LocaleEnum.CZECH]: 'Čeština',
  [LocaleEnum.HUNGARIAN]: 'Magyar',
  [LocaleEnum.SLOVAK]: 'Slovenský',
  [LocaleEnum.DANISH]: 'Dansk',
  [LocaleEnum.INDONESIAN]: 'Indonesian',
  [LocaleEnum.POLISH]: 'Polski',
  [LocaleEnum.GREEK]: 'Ελληνικά',
  [LocaleEnum.SERBIAN]: 'Srpski',
  [LocaleEnum.HINDI]: 'Hindi',
  [LocaleEnum.NORWEGIAN]: 'Norsk',
  [LocaleEnum.THAI]: 'ไทย',
  [LocaleEnum.SLOVENIAN]: 'Slovenič',
  [LocaleEnum.UKRAINIAN]: 'Український',
  [LocaleEnum.ALBANIA]: 'Shqiptare',
}

export const localeLanguages: Record<LocaleEnum, string> = {
  [LocaleEnum.ENGLISH]: 'locale.english',
  [LocaleEnum.FRENCH]: 'locale.french',
  [LocaleEnum.SPANISH]: 'locale.spanish',
  [LocaleEnum.ITALIAN]: 'locale.italian',
  [LocaleEnum.PORTUGUESE]: 'locale.portuguese',
  [LocaleEnum.DEUTSCH]: 'locale.german',
  [LocaleEnum.DUTCH]: 'locale.dutch',
  [LocaleEnum.RUSSIAN]: 'locale.russian',
  [LocaleEnum.JAPANESE]: 'locale.japanese',
  [LocaleEnum.CHINESE]: 'locale.chinese',
  [LocaleEnum.TURKISH]: 'locale.turkish',
  [LocaleEnum.ARABIC]: 'locale.arabic',
  [LocaleEnum.SWEDISH]: 'locale.swedish',
  [LocaleEnum.ROMANIAN]: 'locale.romanian',
  [LocaleEnum.CZECH]: 'locale.czech',
  [LocaleEnum.HUNGARIAN]: 'locale.hungarian',
  [LocaleEnum.SLOVAK]: 'locale.slovak',
  [LocaleEnum.DANISH]: 'locale.danish',
  [LocaleEnum.INDONESIAN]: 'locale.indonesian',
  [LocaleEnum.POLISH]: 'locale.polish',
  [LocaleEnum.GREEK]: 'locale.greek',
  [LocaleEnum.SERBIAN]: 'locale.serbian',
  [LocaleEnum.HINDI]: 'locale.hindi',
  [LocaleEnum.NORWEGIAN]: 'locale.norwegian',
  [LocaleEnum.THAI]: 'locale.thai',
  [LocaleEnum.SLOVENIAN]: 'locale.slovenian',
  [LocaleEnum.UKRAINIAN]: 'locale.ukrainian',
  [LocaleEnum.ALBANIA]: 'locale.albania',
}
