import React, { useState } from 'react'
import { useHideItem } from 'shared/components/header/hooks/use-hide-item'
import { useIsActive } from 'shared/components/header/hooks/use-is-active'
import {
  MenuItemInterface,
  SingleItemDropdownInterface,
  SingleItemHrefInterface,
} from 'shared/components/header/types/menu-interface'
import { isFlatMenuItem } from 'shared/components/header/utils/is-flat-menu-item'
import { replaceHelpHref } from 'shared/components/header/utils/replace-help-href'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'

const SideMenuItem = ({ item }: MenuItemInterface) =>
  isFlatMenuItem(item) ? <SideMenuItemFlat {...item} /> : <SideMenuDropdown {...item} />

export default SideMenuItem

export function SideMenuItemFlat(item: SingleItemHrefInterface) {
  const isActive = useIsActive(item)

  const { user } = useUser()
  const needToHide = useHideItem()

  if (!user) return null

  if (needToHide(item)) return null

  return (item.showForRoles || user.roles).some(role => user.roles.includes(role)) ? (
    <div className="inline-block relative transition-all group">
      <div
        className={`flex gap-4 items-center ${isActive && '[&>svg]:fill-blue'} [&>svg]:w-[24px]`}
      >
        {item.mobileIconRenderer && item.mobileIconRenderer()}
        <LinkWithoutPrefetch
          className={`${
            isActive ? 'text-blue' : 'text-darkblue'
          } tracking-wide inline-flex items-center grow text-lg font-bold`}
          href={replaceHelpHref(item.href, user.dashboardLocale)}
          target={item.target}
        >
          {item.name}
        </LinkWithoutPrefetch>
      </div>
    </div>
  ) : null
}

function SideMenuDropdown(item: SingleItemDropdownInterface) {
  const { user } = useUser()
  const isActive = useIsActive(item)
  const [open, setOpen] = useState(false)
  const needToHide = useHideItem()
  const filteredItems = item.dropdownItems.filter(item => !needToHide(item))
  if (filteredItems.length === 0) return null
  if (!user) return null

  return (
    <div>
      <div
        className={`flex gap-4 items-center cursor-pointer ${
          isActive && '[&>svg]:fill-blue'
        } [&>svg]:w-[24px]`}
        onClick={() => setOpen(prev => !prev)}
      >
        {item.mobileIconRenderer && item.mobileIconRenderer()}
        <div
          className={`${
            isActive ? 'text-blue' : 'text-darkblue'
          } tracking-wide inline-flex items-center grow text-lg font-bold`}
        >
          {item.name}
          <ChevronDownIcon
            className={`ml-auto transition-transform self-center ${open && 'rotate-180'}`}
            width={15}
            height={5}
          />
        </div>
      </div>
      {open && <SideMenuDropdownPanel dropdownItems={filteredItems} />}
    </div>
  )
}

const SideMenuDropdownPanel = ({
  dropdownItems,
}: Pick<SingleItemDropdownInterface, 'dropdownItems'>) => {
  const { user, isWsAssistant } = useUser()
  if (!user) return null
  return (
    <div className="flex flex-col whitespace-nowrap text-sm pt-2 gap-2 pl-10">
      {dropdownItems.map(item => {
        if (isFlatMenuItem(item)) {
          return (
            (item.showForRoles || user.roles).some(role => user.roles.includes(role)) && (
              <SideMenuSubItem
                {...item}
                href={isWsAssistant ? item.assistantLink || item.href : item.href}
                key={item.href}
              />
            )
          )
        } else {
          return <SideSubMenu key={item.name} {...item} />
        }
      })}
    </div>
  )
}

const SideSubMenu = (item: SingleItemDropdownInterface) => {
  const [open, setOpen] = useState(false)
  const isActive = useIsActive(item)
  const needToHide = useHideItem()
  const filteredItems = item.dropdownItems.filter(item => !needToHide(item))
  if (filteredItems.length === 0) return null
  return (
    <div>
      <div
        className={`flex items-center gap-4 cursor-pointer ${
          isActive && '[&>svg]:fill-blue'
        } [&>svg]:w-[24px]`}
        onClick={() => setOpen(prev => !prev)}
      >
        {item.mobileIconRenderer && item.mobileIconRenderer()}
        <div className={getSubItemClass(isActive)}>
          {item.name}
          <ChevronDownIcon
            className={`ml-auto transition-transform self-center ${open && 'rotate-180'}`}
            width={15}
            height={5}
          />
        </div>
      </div>
      {open && <SideMenuDropdownPanel dropdownItems={filteredItems} />}
    </div>
  )
}

function SideMenuSubItem(item: SingleItemHrefInterface) {
  const isActive = useIsActive(item)
  return (
    <LinkWithoutPrefetch className={getSubItemClass(isActive)} href={item.href}>
      {item.name}
    </LinkWithoutPrefetch>
  )
}

const getSubItemClass = (isActive: boolean) =>
  `${
    isActive ? 'text-blue' : 'text-darkblue'
  } mb-1 hover:text-blue text-[15px] font-light flex items-center justify-between w-full main-transition-colors`
