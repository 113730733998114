import * as React from 'react'
import { Trans } from 'react-i18next'
import { TLocoType } from 'shared/hooks/use-loco-translation'

interface TransLocoProps {
  t: TLocoType
  i18nKey: string
  components?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement }
  values?: any
}

const TransLoco = ({ t, i18nKey, components, values }: TransLocoProps) => {
  return <Trans t={() => t(i18nKey)} i18nKey={i18nKey} components={components} values={values} />
}

export default TransLoco
